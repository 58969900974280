import React, { useState } from "react";
import Logo from "../../../../assets/projects/past/blogo.png";
import bal1 from "../../../../assets/projects/past/bal.jpg";
import bal2 from "../../../../assets/projects/past/bal2.JPG";
import bal3 from "../../../../assets/projects/past/bal3.jpg";
const Balwadi = () => {
  const images = [bal1,bal2,bal3];
  const [selectedImage, setSelectedImage] = useState(null);

  const openModal = (image) => setSelectedImage(image);
  const closeModal = () => setSelectedImage(null);
  return (
    <div className="bg-gray-50 min-h-screen py-10 px-5">
      {/* Header Section */}
      <div className="max-w-6xl mx-auto bg-white shadow-md rounded-lg p-6">
        <div className="flex flex-col lg:flex-row items-center justify-between">
          {/* Logo */}
          <img src={Logo} alt="Company Logo" className="h-16 w-auto" />
          {/* Title */}
          <h1 className="text-2xl lg:text-3xl font-bold text-orange-600 text-center">
        Balwadi Project
            <br />
          </h1>
        </div>
      </div>

      {/* Content Section */}
      <div className="max-w-6xl mx-auto bg-white shadow-md rounded-lg mt-8 p-6">
        {/* Introduction */}
        <h2 className="text-xl lg:text-2xl font-semibold text-gray-700 mb-4">
          About the Project
        </h2>
        <p className="text-gray-600 leading-7">
        SAPREM organization runs 12 preschools for 258 children. Three of these
centres, located in the Kamathipura and Ghatkopar neighborhoods of Mumbai, cater to children of
survivors of trafficking. The remaining nine are located in the Govandi and Aarey Colony areas and
serve children from homeless families who are at high risk of being trafficked.
In each preschool, students learn basic skills for the subsequent cycle of compulsory education. At the
same time, children have access to vaccination campaigns and receive a daily food supplements to
help reduce malnutrition levels. This food is prepared by the groups of women from the community
itself, who help themselves through the self-Help Groups (SHGs).
We hold monthly meetings with parents to ensure that families are involved in the academic progress
of their children. We also emphasize on keeping pre-school teachers and assistants trained and
motivated.        </p>
<h2 className="text-xl lg:text-2xl font-semibold text-gray-700 mt-8">
        Health and nutrition:
        </h2>
        <p className="text-gray-600 leading-7 mt-4">
        In the area of health, all pupils receive medical check-ups and follow-up on various health-related
issues. In addition to nutritional support, monthly height and weight records are kept to detect
possible cases of malnutrition and to intervene by involving and informing the families or making
hospital referrals for the most severe cases.
The project has been running successfully since its inception in 2010, and has gradually expanded its
coverage, the range of activities on offer and the quality of teaching. It has also involved 130 women
who are responsible for preparing the daily nutritional supplements for the participating children and
who are receiving salary for their work.
        </p>

        {/* Project Outcomes */}
       
        <h3 className="text-lg lg:text-xl font-semibold text-orange-600 mt-6">
        Project priorities:        </h3>
        <ul className="list-disc list-inside text-gray-600 mt-4 space-y-2">
          <li>
          To provide preschool education with supplementary nutrition to underprivileged 
          children.
          </li>
          <li>
          To provide education to underprivileged children.
          </li>
          <li>To help parents and families understand the importance  of education and health.</li>
          <li>To prevent the exploitation of poor children.</li>
          <li>
          To organize medical camps for the underprivileged.
          </li>
         
        </ul>

        {/* Conclusion */}
        
      </div>
      <br />
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
        {images.map((image, index) => (
          <div
            key={index}
            className="relative group cursor-pointer"
            onClick={() => openModal(image)}
          >
            <img
              src={image}
              alt={`Project ${index + 1}`}
              className="w-full h-64 object-cover rounded-lg shadow-md transition-transform duration-300 transform group-hover:scale-105"
            />
          </div>
        ))}
      </div>

      {selectedImage && (
        <div
          className="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center z-50"
          onClick={closeModal}
        >
          <div className="relative max-w-3xl w-full p-4">
            <img
              src={selectedImage}
              alt="Selected"
              className="w-full max-h-[80vh] object-contain rounded-lg"
            />
            <button
              onClick={closeModal}
              className="absolute top-4 right-4 bg-white text-black rounded-full p-2 text-lg font-bold shadow-md hover:shadow-lg"
            >
              ✕
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Balwadi;
