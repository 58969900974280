import React, { useState } from "react";
import p1 from "../../../../assets/projects/o1.png";
import p2 from "../../../../assets/projects/o2.png";
import p3 from "../../../../assets/projects/o3.png";
import p4 from "../../../../assets/projects/o4.png";
import p5 from "../../../../assets/projects/o5.png";
const OldAgeHome = () => {
  const images = [p1, p2, p3, p4, p5];
  const [selectedImage, setSelectedImage] = useState(null);

  const openModal = (image) => setSelectedImage(image);
  const closeModal = () => setSelectedImage(null);
  return (
    <div className="p-5">
      <div className="bg-gray-50 p-6 md:p-12 rounded-lg shadow-md">
        {/* Header Section */}
        <header className="text-center mb-8">
          <h1 className="text-3xl font-bold text-blue-700">
            Kelungan Old Age Home Project
          </h1>
          <h2 className="text-xl font-semibold text-gray-600 mt-2">
            Supporting the Elderly with Sustainability and Care
          </h2>
        </header>

        {/* Content Section */}
        <section className="text-gray-700 mb-8">
          <p>
            Old Age Home Kelungan is located in the Akole Block, a remote area
            of Ahmednagar District. It provides residence to 55 elderly people.
            The Dorf Ketal CSR initiative has undertaken various activities to
            improve their living conditions. These include:
          </p>
          <ul className="list-disc pl-5 mt-4">
            <li>
              Establishing a kitchen garden for fresh vegetables used in daily
              meals.
            </li>
            <li>
              Installing a fridge, water cooler, and necessary connections.
            </li>
            <li>
              Conducting tree plantation activities, with a focus on fruit
              plants to provide fresh fruits for the residents.
            </li>
            <li>
              Laying a water pipeline from an external source to the old age
              home and setting up a water tank to store water for the plants and
              kitchen garden.
            </li>
          </ul>
        </section>

        {/* Gram Sabha Section */}
        <section className="bg-blue-100 p-4 rounded-lg shadow-md text-gray-800 mb-8">
          <h3 className="text-lg font-semibold text-blue-700 mb-2">
            Gramsabha
          </h3>
          <p>
            The project team organized numerous meetings across various villages
            to raise awareness about multiple initiatives. These meetings were
            attended by Grampanchayat members, youth, and women. Topics
            discussed included:
          </p>
          <ul className="list-disc pl-5 mt-4">
            <li>Environmental conservation</li>
            <li>Economic development</li>
            <li>Water management</li>
            <li>Rainwater harvesting</li>
          </ul>
        </section>

        {/* Image Section */}
        {/* Image Section */}
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
          {images.map((image, index) => (
            <div
              key={index}
              className="relative group cursor-pointer"
              onClick={() => openModal(image)}
            >
              <img
                src={image}
                alt={`Project ${index + 1}`}
                className="w-full h-64 object-cover rounded-lg shadow-md transition-transform duration-300 transform group-hover:scale-105"
              />
            </div>
          ))}
        </div>

        {selectedImage && (
          <div
            className="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center z-50"
            onClick={closeModal}
          >
            <div className="relative max-w-3xl w-full p-4">
              <img
                src={selectedImage}
                alt="Selected"
                className="w-full max-h-[80vh] object-contain rounded-lg"
              />
              <button
                onClick={closeModal}
                className="absolute top-4 right-4 bg-white text-black rounded-full p-2 text-lg font-bold shadow-md hover:shadow-lg"
              >
                ✕
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default OldAgeHome;
