import React, { useState } from "react";
import { FaBars, FaTimes } from "react-icons/fa";
import { Link } from "react-router-dom";
import { IoMdArrowDropdown } from "react-icons/io"; // Import the down arrow icon
import logo from "../../assets/logo.webp";

const Navbar = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [activeLink, setActiveLink] = useState(null);

  // Links Array
  const links = [
    { name: "Home", to: "/" },
    { name: "About", to: "/about" },
    {
      name: "Projects",
      subLinks: [
        { name: "Past Projects", to: "/pastProjects" },
        { name: "Present Projects", to: "/presentProjects" },
      ],
    },
    { name: "Donate", to: "/donate" },
    {
      name: "Media",
      subLinks: [
        { name: "Gallery", to: "/gallery" },
        { name: "Blog", to: "/blog" },
        { name: "News", to: "/news" },
        { name: "Event", to: "/event" },
        { name: "Video", to:"https://www.youtube.com/channel/UCtKjoV2rSaO7evrb4gvQZFg" },

      ],
    },
    { name: "Reports", to: "/reports" },
    { name: "Contact", to: "/contact" },
  ];

  const toggleSubLinks = (index) => {
    setActiveLink(activeLink === index ? null : index);
  };

  const handleSubLinkClick = () => {
    setActiveLink(null);
    setIsSidebarOpen(false);
  };

  return (
    <div className="fixed top-14 lg:top-11 left-0 w-full bg-white text-black z-50 shadow-md">
      {/* Navbar for Large Screens */}
      <div className="hidden sm:flex justify-between max-w-7xl mx-auto items-center px-6 py-3 border-b border-gray-200">
        <Link to="/">
          <img src={logo} alt="Logo" className="h-16" />
        </Link>
        <div className="flex space-x-6">
          {links.map((link, index) => (
            <div key={index} className="relative group">
              <div
                className="flex items-center cursor-pointer hover:text-gray-600"
                onClick={() => link.subLinks && toggleSubLinks(index)}
              >
                <Link to={link.to || "#"}>{link.name}</Link>
                {link.subLinks && (
                  <IoMdArrowDropdown className="ml-1 text-gray-600" />
                )}
              </div>
              {link.subLinks && activeLink === index && (
                <div className="absolute top-6 -left-3 w-[13vw] hidden group-hover:block bg-white border border-gray-300 p-2 rounded shadow-lg">
                  {link.subLinks.map((subLink, subIndex) => (
                    <Link
                      key={subIndex}
                      to={subLink.to}
                      className="block px-4 py-2 hover:bg-gray-100"
                    >
                      {subLink.name}
                    </Link>
                  ))}
                </div>
              )}
            </div>
          ))}
        </div>
      </div>

      {/* Hamburger Menu for Small Devices */}
      <div className="sm:hidden flex justify-between items-center px-6 py-4 border-b border-gray-200">
        <Link to="/">
          <img src={logo} alt="Logo" className="h-10" />
        </Link>
        <button onClick={() => setIsSidebarOpen(!isSidebarOpen)}>
          {isSidebarOpen ? <FaTimes size={24} /> : <FaBars size={24} />}
        </button>
      </div>

      {/* Sidebar for Small Devices */}
      {isSidebarOpen && (
        <>
          {/* Overlay */}
          <div
            className="fixed inset-0 bg-black bg-opacity-50 z-40"
            onClick={() => setIsSidebarOpen(false)}
          ></div>
          {/* Sidebar */}
          <div className="fixed top-0 left-0 w-64 h-screen bg-white shadow-lg z-50">
            <div className="flex justify-between items-center px-6 py-4 border-b border-gray-200">
              <Link to="/">
                <img src={logo} alt="Logo" className="h-10" />
              </Link>
              <button onClick={() => setIsSidebarOpen(false)}>
                <FaTimes size={24} />
              </button>
            </div>
            <div className="px-6 py-4">
              {links.map((link, index) => (
                <div key={index} className="mb-4">
                  <div
                    className="flex items-center justify-between w-full text-left cursor-pointer hover:text-gray-600"
                    onClick={() =>
                      link.subLinks
                        ? toggleSubLinks(index)
                        : setIsSidebarOpen(false)
                    }
                  >
                    <Link to={link.to || "#"}>{link.name}</Link>
                    {link.subLinks && (
                      <IoMdArrowDropdown className="ml-1 text-gray-600" />
                    )}
                  </div>
                  {link.subLinks && activeLink === index && (
                    <div className="mt-2 ml-4">
                      {link.subLinks.map((subLink, subIndex) => (
                        <Link
                          key={subIndex}
                          to={subLink.to}
                          className="block px-4 py-2 hover:bg-gray-100 rounded"
                          onClick={handleSubLinkClick}
                        >
                          {subLink.name}
                        </Link>
                      ))}
                    </div>
                  )}
                </div>
              ))}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default Navbar;
