import React, { useState } from "react";
import Logo from "../../../../assets/projects/past/logo.png";
import p1 from "../../../../assets/projects/past/s1.png";
import p2 from "../../../../assets/projects/past/s2.png";
import p3 from "../../../../assets/projects/past/s3.png";
import p4 from "../../../../assets/projects/past/s4.png";
import p5 from "../../../../assets/projects/past/s5.png";
import p6 from "../../../../assets/projects/past/s6.png";
import p7 from "../../../../assets/projects/past/s7.png";
import p8 from "../../../../assets/projects/past/s8.png";
import p9 from "../../../../assets/projects/past/s9.png";

const School = () => {
  const images = [p1, p2, p3, p4, p5, p6, p7, p8, p9];
  const [selectedImage, setSelectedImage] = useState(null);

  const openModal = (image) => setSelectedImage(image);
  const closeModal = () => setSelectedImage(null);

  return (
    <div className="bg-gray-50 min-h-screen py-10 px-5">
      {/* Header Section */}
      <div className="max-w-6xl mx-auto bg-white shadow-md rounded-lg p-6">
        <div className="flex flex-col lg:flex-row items-center justify-between">
          {/* Logo */}
          <img src={Logo} alt="Company Logo" className="h-16 w-auto" />
          {/* Title */}
          <h1 className="text-2xl lg:text-3xl font-bold text-orange-600 text-center">
            BRING A SMILE ENGLISH MEDIUM SCHOOL
          </h1>
        </div>
      </div>

      {/* Overview Section */}
      <div className="max-w-6xl mx-auto mt-8 bg-white shadow-md rounded-lg p-6">
        <h2 className="text-xl font-bold text-gray-800 mb-4">Overview</h2>
        <p className="text-gray-700 leading-7">
          SAPREM NGO's initiative with the Bring a Smile English School for
          rural and tribal children in Birwadi, Sainagar, Tal Shahapur, Dist
          Thane. Providing education to children from marginalized communities
          is crucial for their holistic development and empowerment.
        </p>
        <p className="text-gray-700 leading-7 mt-2">
          Having facilities like transportation, trained teachers, counsellors,
          and green surroundings not only ensures a conducive learning
          environment but also addresses various aspects of their well-being.
          Having 122 children enrolled from 15 nearby villages shows the impact
          and reach of the school in the community.
        </p>
        <p className="text-gray-700 leading-7 mt-2">
          Access to education and a decent standard of living are fundamental
          rights, and initiatives like these play a vital role in ensuring these
          rights are upheld. The presence of amenities like a computer lab,
          playground, and play equipment further enriches the learning
          experience and contributes to the overall growth of the children.
        </p>
        <p className="text-gray-700 leading-7 mt-2">
          It's heartening to see organizations like SAPREM taking proactive
          steps towards creating a brighter future for these children and their
          communities.
        </p>
      </div>

      {/* Objectives Section */}
      <div className="max-w-6xl mx-auto mt-8 bg-white shadow-md rounded-lg p-6">
        <h2 className="text-xl font-bold text-gray-800 mb-4">Objective</h2>
        <p className="text-gray-700 leading-7">
          English Medium School facility provided for children of rural, tribal,
          and marginalized populations.
        </p>
      </div>

      {/* Achievements Section */}
      <div className="max-w-6xl mx-auto mt-8 bg-white shadow-md rounded-lg p-6">
        <h2 className="text-xl font-bold text-gray-800 mb-4">Achievements</h2>
        <p className="text-gray-700 leading-7">
          Parents and villagers are very happy to have the English medium school
          with all amenities and trained staff and management in their village,
          Birwadi, Taluka Shahapur, Dist Thane.
        </p>
        <p className="text-gray-700 leading-7 mt-2">
          <strong>Result:</strong>
        </p>
        <ul className="list-disc list-inside text-gray-700 mt-2">
          <li>Total Strength: 122 children from Nursery to 5th Standard</li>
          <li>9 Teachers</li>
          <li>2 Assistants</li>
          <li>1 Clerk cum Accountant</li>
          <li>1 Head Master</li>
          <li>1 Caretaker</li>
          <li>1 School Bus Driver</li>
        </ul>
      </div>

      {/* Gallery Section */}
      <div className="max-w-6xl mx-auto mt-8">
        <h2 className="text-xl font-bold text-gray-800 mb-4">Gallery</h2>
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
          {images.map((image, index) => (
            <div
              key={index}
              className="relative group cursor-pointer"
              onClick={() => openModal(image)}
            >
              <img
                src={image}
                alt={`Project ${index + 1}`}
                className="w-full h-full object-cover rounded-lg shadow-md transition-transform duration-300 transform group-hover:scale-105"
              />
            </div>
          ))}
        </div>

        {selectedImage && (
          <div
            className="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center z-50"
            onClick={closeModal}
          >
            <div className="relative max-w-3xl w-full p-4">
              <img
                src={selectedImage}
                alt="Selected"
                className="w-full max-h-[80vh] object-contain rounded-lg"
              />
              <button
                onClick={closeModal}
                className="absolute top-4 right-4 bg-white text-black rounded-full p-2 text-lg font-bold shadow-md hover:shadow-lg"
              >
                ✕
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default School;
