import React from "react";

const BlogPage = () => {
  const blogPosts = [
    {
      title: "Helping Hands: Our Latest Campaign",
      date: "December 10, 2024",
      image:
        "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQA6_pjHiq7XBGyeNmsHrbLLBz2Bfe6TSKgFg&s",
      summary:
        "This campaign focuses on supporting underprivileged communities through food and education.",
    },
    {
      title: "Clean Water for All",
      date: "November 20, 2024",
      image:
        "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQqxT60z3FgfCzlGKFzY_guWTMSSIrSFalmyA&s",
      summary:
        "Our mission to provide clean water solutions to remote villages is making a big impact.",
    },
    {
      title: "Child Education Initiatives",
      date: "October 15, 2024",
      image:
        "https://satyarthi.org.in/wp-content/uploads/2021/09/girl-student_6139ecd847750.jpg",
      summary:
        "We are helping children from low-income families gain access to quality education.",
    },
  ];

  return (
    <div className="bg-gray-50 py-16 px-6">
      <h1 className="text-5xl font-bold text-center text-teal-600 mb-16 tracking-wide">
        Our Blog
      </h1>

      <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-8">
        {blogPosts.map((post, index) => (
          <div
            key={index}
            className="bg-white rounded-lg shadow-lg hover:shadow-2xl transition-all duration-300 ease-in-out transform hover:scale-105 overflow-hidden"
          >
            <img
              src={post.image}
              alt={post.title}
              className="w-full h-56 object-cover rounded-t-lg"
            />
            <div className="p-6">
              <h2 className="text-2xl font-semibold text-gray-800 hover:text-teal-600 transition-all duration-200 mb-2">
                {post.title}
              </h2>
              <p className="text-sm text-gray-400 mt-2">{post.date}</p>
              <p className="text-gray-600 mt-4 text-base">{post.summary}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default BlogPage;
