import React, { useState } from "react";
import p1 from "../../../../assets/projects/1.png";
import p2 from "../../../../assets/projects/2.png";
import p3 from "../../../../assets/projects/3.png";
import p4 from "../../../../assets/projects/4.png";
import p5 from "../../../../assets/projects/5.png";
import p6 from "../../../../assets/projects/6.png";

const Pride = () => {
  const images = [p1, p2, p3, p4, p5, p6];
  const [selectedImage, setSelectedImage] = useState(null);

  const openModal = (image) => setSelectedImage(image);
  const closeModal = () => setSelectedImage(null);
  return (
    <div className="p-5">
      <div className="bg-gray-50 p-6 md:p-12">
        <header className="text-center mb-8">
          <h1 className="text-3xl font-bold text-purple-700">
            PRIDE - Bright Future for Transgender Groups
          </h1>
          <h2 className="text-xl font-semibold text-gray-600 mt-2">
            Shrirampur, Ahmednagar District
          </h2>
        </header>
        <section className="text-gray-700 mb-8">
          <p>
            Transgender is another community group that often relies on begging
            and performing local arts for their livelihood. Shrirampur, a block
            in Ahmednagar District, is home to various populations. Among them
            is the *Trutiya Panthi Samaj Seva Mandal*, a group of 45 transgender
            individuals. We are supporting this group to improve their economic
            conditions, helping them lead dignified lives. The PRIDE project is
            an essential initiative aimed at empowering the transgender
            community to move beyond their traditional professions.
          </p>
        </section>
        <section className="text-gray-700 mb-8">
          <h3 className="text-xl font-semibold text-purple-700 mb-4">
            Success Story: Malini's Transformation
          </h3>
          <p>
            Malini, a transgender individual from Mumbai, has become a role
            model through her journey of transformation. She started her own
            business selling Bisleri water bottles and has been supported
            through the PRIDE project. Using her innovation, she purchased an
            electric bike to transport water bottles to various shops. This
            initiative has helped her grow her business, repay her bank loans,
            and start earning profits.
          </p>
          <p>
            Her success showcases the potential for the transgender community to
            move beyond traditional livelihood methods and take up opportunities
            aligned with their qualifications and aspirations.
          </p>
        </section>

        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
          {images.map((image, index) => (
            <div
              key={index}
              className="relative group cursor-pointer"
              onClick={() => openModal(image)}
            >
              <img
                src={image}
                alt={`Project ${index + 1}`}
                className="w-full h-64 object-cover rounded-lg shadow-md transition-transform duration-300 transform group-hover:scale-105"
              />
            </div>
          ))}
        </div>

        {selectedImage && (
          <div
            className="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center z-50"
            onClick={closeModal}
          >
            <div className="relative max-w-3xl w-full p-4">
              <img
                src={selectedImage}
                alt="Selected"
                className="w-full max-h-[80vh] object-contain rounded-lg"
              />
              <button
                onClick={closeModal}
                className="absolute top-4 right-4 bg-white text-black rounded-full p-2 text-lg font-bold shadow-md hover:shadow-lg"
              >
                ✕
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Pride;
