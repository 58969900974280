import React, { useState } from "react";
import p1 from "../../../../assets/projects/j1.png";
import p2 from "../../../../assets/projects/j2.png";
import p3 from "../../../../assets/projects/j3.png";
import p4 from "../../../../assets/projects/j4.png";
import p5 from "../../../../assets/projects/j5.png";
import p6 from "../../../../assets/projects/j6.png";
import p7 from "../../../../assets/projects/j7.png";
import p8 from "../../../../assets/projects/j8.png";
import p9 from "../../../../assets/projects/j9.png";
import p10 from "../../../../assets/projects/Jaldhara1.jpeg";
import p11 from "../../../../assets/projects/jaldhara2.jpeg";

const Jaldhara = () => {
  const images = [p10,p11,p1, p2, p3, p4, p5, p6, p7, p8, p9];
  const [selectedImage, setSelectedImage] = useState(null);

  const openModal = (image) => setSelectedImage(image);
  const closeModal = () => setSelectedImage(null);
  return (
    <div className="p-5">
      <div className="bg-gray-50 p-6 md:p-12">
        <header className="text-center mb-8">
          <h1 className="text-3xl font-bold text-blue-700">Jaldhara Project</h1>
          <h2 className="text-xl font-semibold text-gray-600 mt-2">
            Rain Water Harvesting Project
          </h2>
        </header>
        <section className="text-gray-700 mb-8">
          <p>
            Shendewadi is the last remote village of the Sangamner Block. The
            total population is 1800, and 80% of the population belongs to
            Tribal Communities. Farming is their key profession, and they depend
            on the rainy season. Bajra, Jawar, Wheat, Onion, etc., are their key
            farm products, but they are only able to grow when there is water in
            their wells. It’s a seasonal farm activity village with no other
            income source. Therefore,
          </p>
        </section>
        <section className="text-gray-700 mb-8">
          <p>
            The Grampanchayat approached SAPREM to help them construct a Soil
            Dam to catch the water near their village area. The dam will store
            water for the summer season, enabling farmers to carry out farming
            in other seasons. The economic condition of the village is expected
            to improve, which is the key objective behind constructing the
            Jaldhara Project. Villagers are ready to contribute through
            Shramdaan (voluntary labor) and provide some amount towards the
            project's completion. They are actively participating in the
            construction activities along with the vendor. The project is
            expected to be completed by the end of March. The water storage
            capacity will be significant, and it will be calculated once the
            project is inaugurated.
          </p>
        </section>
        <section className="text-gray-700 mb-8">
          <p>
            <strong>Villages Name:</strong> Shendewadi, Tal. Sangamner, Dist.
            Ahmednagar
          </p>
        </section>
      </div>

      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
        {images.map((image, index) => (
          <div
            key={index}
            className="relative group cursor-pointer"
            onClick={() => openModal(image)}
          >
            <img
              src={image}
              alt={`Project ${index + 1}`}
              className="w-full h-64 object-cover rounded-lg shadow-md transition-transform duration-300 transform group-hover:scale-105"
            />
          </div>
        ))}
      </div>

      {selectedImage && (
        <div
          className="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center z-50"
          onClick={closeModal}
        >
          <div className="relative max-w-3xl w-full p-4">
            <img
              src={selectedImage}
              alt="Selected"
              className="w-full max-h-[80vh] object-contain rounded-lg"
            />
            <button
              onClick={closeModal}
              className="absolute top-4 right-4 bg-white text-black rounded-full p-2 text-lg font-bold shadow-md hover:shadow-lg"
            >
              ✕
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Jaldhara;
