import React from "react";
import { Link } from "react-router-dom";


const Reports = () => {
  const reports = [
    {
      id: 1,
      title: "PRA Report",
      description: "Click below to download the PRA Report.",
      link: "http://sapremngo.in/wp-content/pra-report.pdf",
      bgColor: "bg-blue-500",
      hoverColor: "hover:bg-blue-600",
    },
    {
      id: 2,
      title: "Annual Report",
      description: "Click below to download the Annual Report.",
      link: "https://drive.google.com/file/d/1sadPeg4mPLx_3orLO6YT1AupTsfqNphK/view",
      bgColor: "bg-green-500",
      hoverColor: "hover:bg-green-600",
    },
    {
      id: 3,
      title: "Article",
      description: "Click below to download the Article.",
      link: "https://drive.google.com/file/d/1vfLGBCkSsuFNIjdfQxFhNJFQq4nh1Am7/view",
      bgColor: "bg-red-500",
      hoverColor: "hover:bg-red-600",
    },
  ];

  return (
    <div className="bg-gray-100 py-10 min-h-screen">
      <div className="max-w-6xl mx-auto px-4">
        <h1 className="text-3xl font-bold text-gray-800 text-center mb-8">
          Reports
        </h1>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          {reports.map((report) => (
            <div
              key={report.id}
              className="bg-white rounded-lg shadow-md p-6 flex flex-col items-center text-center transition hover:shadow-lg"
            >
              <h2 className="text-xl font-semibold text-gray-700 mb-4">
                {report.title}
              </h2>
             
              <p className="text-gray-600 mb-6">{report.description}</p>
              <Link
                to={report.link}
                download
                className={`inline-block px-6 py-3 text-white rounded-full font-medium ${report.bgColor} ${report.hoverColor} transition`}
              >
                Download
              </Link>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Reports;
