import React, { useState } from "react";
import Logo from "../../../../assets/projects/past/alogo.png";
import p1 from "../../../../assets/projects/past/a1.png";
import p2 from "../../../../assets/projects/past/a2.png";
import p3 from "../../../../assets/projects/past/a3.png";
import p4 from "../../../../assets/projects/past/a4.png";
import p5 from "../../../../assets/projects/past/a5.png";
import p6 from "../../../../assets/projects/past/a6.png";
const Anganwadi = () => {
  const images = [p1, p2, p3, p4, p5, p6];
  const [selectedImage, setSelectedImage] = useState(null);

  const openModal = (image) => setSelectedImage(image);
  const closeModal = () => setSelectedImage(null);
  return (
    <div className="bg-gray-50 min-h-screen py-10 px-5">
      {/* Header Section */}
      <div className="max-w-6xl mx-auto bg-white shadow-md rounded-lg p-6">
        <div className="flex flex-col lg:flex-row items-center justify-between">
          {/* Logo */}
          <img src={Logo} alt="Company Logo" className="h-16 w-auto" />
          {/* Title */}
          <h1 className="text-2xl lg:text-3xl font-bold text-orange-600 text-center">
            ANGANWADI IMPROVEMENT PROJECT
            <br />
            FOR RURAL AND TRIBAL AREA AT JALANA DISTRICT
          </h1>
        </div>
      </div>

      {/* Content Section */}
      <div className="max-w-6xl mx-auto bg-white shadow-md rounded-lg mt-8 p-6">
        {/* Introduction */}
        <h2 className="text-xl lg:text-2xl font-semibold text-gray-700 mb-4">
          About the Project
        </h2>
        <p className="text-gray-600 leading-7">
          SAPREM has experienced that if we provide a child-friendly atmosphere,
          some training, and nutrition to Anganwadi children in the age group of
          1-6 years, teachers with social skills can serve underprivileged
          children with the right to compete equally with good education.
        </p>
        <p className="text-gray-600 leading-7 mt-4">
          SAPREM initiated this project to work for the rural community for
          their better and sustainable development. This development will come
          through education only.
        </p>

        {/* Project Outcomes */}
        <h2 className="text-xl lg:text-2xl font-semibold text-gray-700 mt-8">
          Project Outcomes
        </h2>
        <p className="text-gray-600 leading-7 mt-4">
          Improvement in 10 Anganwadis has benefited 300 children (below 6 years
          of age – each Anganwadi serves 30 children), providing nutritious food
          and education for a better future. Beneficiaries will be linked from
          Anganwadi to primary and secondary schools, shaping the overall growth
          of the children economically and socially. This program will have a
          long-term impact in alleviating poverty.
        </p>

        <h3 className="text-lg lg:text-xl font-semibold text-orange-600 mt-6">
          Key Focus Areas:
        </h3>
        <ul className="list-disc list-inside text-gray-600 mt-4 space-y-2">
          <li>
            Repairing and painting of 10 Anganwadis to create a child-friendly
            atmosphere.
          </li>
          <li>
            Coordinating with government authorities to restart nutritious food
            programs for Anganwadi children.
          </li>
          <li>Building a strong educational foundation for the children.</li>
          <li>Creating child-friendly environments in 10 Anganwadis.</li>
          <li>
            Integrating children into formal preschool centers (semi-government,
            private, and public).
          </li>
          <li>
            Providing pre-primary education to children by trained teachers.
          </li>
          <li>
            Making parents aware of their responsibilities in their children’s
            education and health progress.
          </li>
          <li>
            Improving nutritional indicators through food provision by SHGs
            (Self-Help Groups), offering sustainable income to SHG members.
          </li>
          <li>
            Ensuring all children are immunized according to government norms,
            including monitoring and evaluation.
          </li>
        </ul>

        {/* Conclusion */}
        <p className="text-gray-600 leading-7 mt-6">
          People with higher levels of academic achievement and more years of
          schooling earn more than those with lower levels of human capital.
          This project will have long-term improvements in education and health
          outcomes, helping children escape poverty as adults.
        </p>
      </div>
      <br />
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
        {images.map((image, index) => (
          <div
            key={index}
            className="relative group cursor-pointer"
            onClick={() => openModal(image)}
          >
            <img
              src={image}
              alt={`Project ${index + 1}`}
              className="w-full h-64 object-cover rounded-lg shadow-md transition-transform duration-300 transform group-hover:scale-105"
            />
          </div>
        ))}
      </div>

      {selectedImage && (
        <div
          className="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center z-50"
          onClick={closeModal}
        >
          <div className="relative max-w-3xl w-full p-4">
            <img
              src={selectedImage}
              alt="Selected"
              className="w-full max-h-[80vh] object-contain rounded-lg"
            />
            <button
              onClick={closeModal}
              className="absolute top-4 right-4 bg-white text-black rounded-full p-2 text-lg font-bold shadow-md hover:shadow-lg"
            >
              ✕
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Anganwadi;
