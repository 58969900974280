import React from "react";
import { FaMapMarkerAlt } from "react-icons/fa"; // Using an icon for location
import t1 from "../../../assets/team/t1.jpeg";
import t2 from "../../../assets/team/t2.jpeg";
import t3 from "../../../assets/team/t3.jpeg";
const Team = () => {
  const teamMembers = [
    {
      name: "Mr. ARJUN GAIKWAD",
      role: "PRESIDENT",
      address:
        "Surabhi Apartment 2nd Floor, Opp. Balvikas Vidylaya, Badlapur Dist: Thane",
      image: t1,
    },
    {
      name: "Mr. ANIL KAVTHEKAR",
      role: "SECRETARY",
      address: "C.007, vijeta shri complex, Belavali, Badlapur (w",
      image: t2,
    },
    {
      name: "Mr. BALASAHEB GAIKWAD",
      role: "TREASURER",
      address:
        "Nanda Apartment, Near Gurudham Hotel, Poona Link Road, Behind KDMC D Ward Office, Vijay Nagar, Kalyan East.421306",
      image: t3,
    },
  ];

  return (
    <div>
      <p className="text-center text-3xl font-bold mt-5">Meet Our Team</p>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6   max-w-7xl mx-auto p-5 ">
        {teamMembers.map((member, index) => (
          <div
            key={index}
            className="bg-white p-6 rounded-lg shadow-lg text-center"
          >
            {/* Image Section */}
            <img
              src={member.image}
              alt={member.name}
              className="w-32 h-32 rounded-full mx-auto mb-4 object-cover"
            />

            {/* Name and Role */}
            <h3 className="text-xl font-semibold text-blue-600">
              {member.name}
            </h3>
            <p className="text-lg text-gray-700 mb-2">{member.role}</p>

            {/* Address Section with Icon */}
            <div className="flex justify-center items-center mb-4">
              <FaMapMarkerAlt className="text-red-500 mr-2" />
              <p className="text-sm text-gray-600">{member.address}</p>
            </div>
          </div>
        ))}
      </div>
      {/* <p className="text-center text-3xl font-bold mt-5">Management</p> */}
    </div>
  );
};

export default Team;
