import React from "react";
import { FaDownload } from "react-icons/fa"; // Importing a download icon
import { Link } from "react-router-dom";
import c1 from "../assets/common/c1.jpg";
import qr from "../assets/qr.jpg";

const Donet = () => {
  return (
    <div>
      <div className="text-center mb-12">
        <img
          src={c1}
          alt="SAPREM Banner"
          className="w-full h-auto  shadow-xl"
        />
      </div>
      <div className="max-w-7xl mx-auto p-5">
        {/* Banner Image */}

        {/* Content Section */}
        <div className="text-lg text-gray-800 space-y-6">
          <p>
            <strong className="text-xl font-semibold text-blue-700">
              Social Aspiration for Participatory Reforms by Evolved Manpower
              (SAPREM)
            </strong>
            , is a grassroots, non-profit development organization, registered
            and managed by professionals, based in Kalyan, Dist: Thane,
            Maharashtra State (India). Our interventions have been ongoing for
            the last Thirteen Years in the fields of Health (community
            preventive health), Education (Elementary), Sponsorship (orphan &
            needy students), Special Education (Mentally Retarded Children),
            Income Generation (through SHGs), Community Empowerment, Natural
            Resource Management, HIV/AIDS awareness, and Urban (slum improvement
            & rehabilitation) and Rural Development.
          </p>
          <p>
            To lead the Education objective, SAPREM runs a school for special
            children (mentally retarded) that operates with the support of
            individual donors and contributors. Their support is crucial to us,
            but it varies from individual to individual, month to month, and
            activity to activity. This program has no sustainable financial
            source to cover recurring costs. The infrastructure for this special
            school has been provided by Kalyan – Dombivli Municipal Corporation
            (KDMC).
          </p>
          <p>
            We would highly appreciate it if you could contribute/donate
            something in cash or kind for this noble cause, focusing on areas of
            your interest. Your support would significantly help, especially in
            the overall development of these 40 Mentally Challenged Children.
          </p>
          <p>
            Your contribution may be in the form of Vocational Training
            Equipment (candle making, chalk making, etc.), Uniforms, Fees,
            Stationery, Books (pre-primary & primary level), Teacher’s Salary,
            Child Counseling, Exercise Equipment, and Toys, among others.
          </p>
          <p>
            We earnestly appeal for your kind support, which may “Bring a Smile”
            to the lives of many children. Your contributions/donations will be
            exclusively utilized for the overall development of these children.
          </p>
          <p>
            You may forward your kind donation/contribution directly to the
            following bank account/school:
          </p>

          {/* Bank Details Section */}
          <div className="bg-gray-100 p-6 rounded-lg shadow-lg  flex justify-between flex-col gap-5 lg:flex-row">
            <div className="">
              <p className="font-semibold text-blue-600">‘SAPREM’</p>
              <p>
                <strong>Bank Account Details:</strong>
              </p>
              <p>
                <strong>Bank:</strong> Bank of Maharashtra
              </p>
              <p>
                <strong>Account Number:</strong> 60133195660
              </p>
              <p>
                <strong>IFSC:</strong> MAHB0001075
              </p>
              <p>
                <strong>Branch:</strong> MHASOBA CHOWK KALYAN TISGAON 421306
                (Maharashtra State, India)
              </p>
            </div>
            <img src={qr} alt="qr code" className="h-[50vh]" />
          </div>

          {/* School Address */}
          <p className="mt-6 text-sm text-gray-600">
            <strong>School Address:</strong>
            <br />
            SAPREM’s “Bring A Smile” (School for Mentally Retarded Children)
            <br />
            Dr. Babasaheb Ambedkar KDMC School
            <br />
            Near Survesh Hall, Behind: Bramhan Sabha Hall, Agararkar Cross Road,
            Dombivli (East), Dist: Thane- 421201, Maharashtra (India)
          </p>

          <p className="mt-4">
            <strong>Email:</strong> saprem.ngo@gmail.com
            <br />
            <strong>Contact Numbers:</strong> 8898861112
          </p>
        </div>

        {/* PDF Button */}
        <div className="mt-12 text-center">
          <Link
            to="http://sapremngo.in/wp-content/uploads/2014/05/Bring-A-Smile-MR-school-appeal.pdf" // Replace with the actual path of your PDF
            target="_blank"
            className="inline-flex items-center bg-blue-600 text-white py-3 px-8 rounded-lg shadow-lg hover:bg-blue-700 transition-all duration-300 transform hover:scale-105"
          >
            <FaDownload className="mr-2" /> Donation PDF
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Donet;
