import React, { useState } from "react";
import Logo from "../../../../assets/projects/past/blogo.png";
import p1 from "../../../../assets/projects/family1.png";
import p2 from "../../../../assets/projects/family2.png";

const Family = () => {
  const images = [p1, p2];
  const [selectedImage, setSelectedImage] = useState(null);

  const openModal = (image) => setSelectedImage(image);
  const closeModal = () => setSelectedImage(null);
  return (
    <div className="bg-gray-50 min-h-screen py-10 px-5">
      {/* Header Section */}
      <div className="max-w-6xl mx-auto bg-white shadow-md rounded-lg p-6">
        <div className="flex flex-col lg:flex-row items-center justify-between">
          {/* Logo */}
          <img src={Logo} alt="Company Logo" className="h-16 w-auto" />
          {/* Title */}
          <h1 className="text-2xl lg:text-3xl font-bold text-orange-600 text-center">
          Family counseling centre , Alibaugh.          </h1>
        </div>
      </div>

      {/* Content Section */}
      <div className="max-w-6xl mx-auto bg-white shadow-md rounded-lg mt-8 p-6">
        {/* Introduction */}
        <h2 className="text-xl lg:text-2xl font-semibold text-gray-700 mb-4">
          About the Project
        </h2>
        <p className="text-gray-600 leading-7">
        The Program seeks to understand the factors deemed critical to implementing an effective response to domestic violence.
Such factors include: the particular perspective and approach to domestic violence, the range of services offered, and the
quality of these services. What follows is an analysis of these factors in relation to specific intervention strategies for the
women’s in the premises of police station.
        </p>
        <p className="text-gray-600 leading-7 mt-4">
        The special cell for women and children for Domestic Violence funded by Women and Child development
department(Government of Maharashtra) since feb.2010 at Alibuag Dist :-Raigad , At Alibag we have appointed two
Counsellors for Counseling the Cases referred by Police station and also came from directly from the community.

<br /><br />
A broad overview of the range of responses to domestic violence reveals innovation and concern on the part of the state as
well as the NGO sector to address the issue of domestic violence. For the purposes of this study, domestic violence was
defined as physical, emotional, sexual, and financial abuse experienced by an adult woman within her home. The universe of
research encompasses both state and NGO responses to domestic violence. The range of NGO responses varied from
provision of alternate shelter, counseling, community mobilization, and education and awareness efforts to advocacy efforts.
        </p>

        {/* Project Outcomes */}
       

        {/* Conclusion */}
        <p className="text-gray-600 leading-7 mt-6">
        Foremost among state interventions have been the criminalization of domestic violence and the passing of several
amendments to address the issue of dowry harassment and dowry death. Other measures have involved efforts to make
legal services more accessible to women. This has resulted in the setting up of Legal Aid Cells, Family Courts, Lok Adalats or
Peoples’ Courts, and Mahila Lok Adalats or Women’s Courts. Attempts to make the police more accessible to women have
taken the form of All Women Police Stations. Police counseling cells, community policing initiatives, and special cells run by
NGOs at police stations have also sought to address different needs of women experiencing abuse. Among the other state
responses have been counseling cells and shelter homes. Certain states initiated district-level programs to address the
additional needs of income generation and employment.
        </p>
      </div>
      <br />
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
        {images.map((image, index) => (
          <div
            key={index}
            className="relative group cursor-pointer"
            onClick={() => openModal(image)}
          >
            <img
              src={image}
              alt={`Project ${index + 1}`}
              className="w-full h-full object-cover rounded-lg shadow-md transition-transform duration-300 transform group-hover:scale-105"
            />
          </div>
        ))}
      </div>

      {selectedImage && (
        <div
          className="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center z-50"
          onClick={closeModal}
        >
          <div className="relative max-w-3xl w-full p-4">
            <img
              src={selectedImage}
              alt="Selected"
              className="w-full max-h-[80vh] object-contain rounded-lg"
            />
            <button
              onClick={closeModal}
              className="absolute top-4 right-4 bg-white text-black rounded-full p-2 text-lg font-bold shadow-md hover:shadow-lg"
            >
              ✕
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Family;
