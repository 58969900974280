import React from "react";
import e from "../../../assets/e.jpg";
import h from "../../../assets/h.webp";
const Features = () => {
  const data = [
    {
      image: "https://www.cry.org/wp-content/themes/cry/images/issue-1.gif",
      title: "Education",
      subtitle: "EDUCATION",
      description: "Education, nutrition, and holistic development of children",
    },
    {
      image: "https://www.cry.org/wp-content/themes/cry/images/issue-2.gif",
      title: "Women Empowerment",
      subtitle: "WOMEN EMPOWERMENT",
      description:
        "Empowering adolescent girls & women through community engagement",
    },
    {
      image: e,
      title: "Rural development ",
      subtitle: "Rural development ",
      description:
        " helping gram panchayat to develop villages for climate change, education, water, farming and empowerment",
    },
    {
      image: h,
      title: "Healthcare",
      subtitle: "HEALTHCARE",
      description:
        "Taking healthcare services to doorsteps of hard-to-reach communities",
    },
  ];

  return (
    <div className="bg-gray-100 py-10">
      <div className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8">
        <h2 className="text-3xl font-bold text-center text-gray-800 mb-8">
          Our Focus Areas
        </h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
          {data.map((item, index) => (
            <div
              key={index}
              className="relative bg-white shadow-lg rounded-lg overflow-hidden group h-72"
            >
              {/* Image and Title */}
              <div className="absolute inset-0 flex flex-col items-center justify-center transition-transform duration-500 group-hover:-translate-y-full">
                <img
                  src={item.image}
                  alt={item.title}
                  className="w-32 h-32 mb-4"
                />
                <h3 className="text-xl font-semibold text-gray-700">
                  {item.title}
                </h3>
              </div>

              {/* Description on Hover */}
              <div className="absolute inset-0 bg-white flex flex-col items-center justify-center px-4 text-center translate-y-full transition-transform duration-500 group-hover:translate-y-0">
                <h3 className="text-lg font-semibold text-gray-700 mb-2">
                  {item.subtitle}
                </h3>
                <p className="text-sm text-gray-600">{item.description}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Features;
