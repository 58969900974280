import React, { useState } from "react";
import n1 from "../assets/news/n1.jpg";
import n2 from "../assets/news/n2.jpg";
import n3 from "../assets/news/n3.jpg";
import n4 from "../assets/news/n4.jpg";
import n5 from "../assets/news/n5.jpg";

const News = () => {
  const [selectedImage, setSelectedImage] = useState(null);

  const newsImages = [n1, n2, n3, n4, n5];

  const handleImageClick = (image) => {
    setSelectedImage(image); // Set the selected image for the modal
  };

  const closeModal = () => {
    setSelectedImage(null); // Close the modal
  };

  return (
    <div className="bg-gray-100 py-10">
      {/* Grid Layout for Images */}
      <div className="max-w-7xl mx-auto grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-6 p-5">
        {newsImages.map((image, index) => (
          <div
            key={index}
            className="relative bg-white shadow-lg rounded-lg overflow-hidden cursor-pointer"
            onClick={() => handleImageClick(image)}
          >
            <img
              src={image}
              alt={`News ${index + 1}`}
              className="w-full h-auto object-contain"
            />
          </div>
        ))}
      </div>

      {/* Modal for Enlarged Image */}
      {selectedImage && (
        <div
          className="fixed inset-0 bg-black bg-opacity-80 flex items-center justify-center z-50"
          onClick={closeModal} // Close modal when clicking outside the image
        >
          <div
            className="relative max-w-4xl w-full p-5"
            onClick={(e) => e.stopPropagation()} // Prevent closing when clicking on the image
          >
            <button
              onClick={closeModal}
              className="absolute top-5 right-5 text-white text-2xl font-bold focus:outline-none"
            >
              &times;
            </button>
            <img
              src={selectedImage}
              alt="Enlarged View"
              className="w-full h-auto object-contain rounded-lg"
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default News;
