import React, { useState } from "react";
import {
  FaFacebook,
  FaInstagram,
  FaLocationDot,
  FaYoutube,
} from "react-icons/fa6";
import { MdEmail } from "react-icons/md";
import { FaPhone } from "react-icons/fa";
import { Link } from "react-router-dom";
// import axios from "axios";
// import Swal from "sweetalert2";

const Contact = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    contact: "",
    message: "",
  });

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
    // console.log(formData);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // Swal.fire({
    //   title: "Loading...",
    //   text: "Please wait while we process your request.",
    //   allowOutsideClick: false,
    //   didOpen: () => {
    //     Swal.showLoading();
    //   },
    // });

    // try {
    //   const response = await axios.post(
    //     "https://i-next-ets.onrender.com/api/v1/user/contact",
    //     formData
    //   );

    //   if (response?.data) {
    //     Swal.fire({
    //       title: "Good job!",
    //       text: "Your message has been sent successfully!",
    //       icon: "success",
    //     });
    //     // console.log(response.data);
    //   }
    //   setFormData({ name: "", email: "", contact: "", message: "" });
    // } catch (error) {
    //   console.log(error);
    //   Swal.fire({
    //     title: "Error!",
    //     text: "There was a problem sending your message. Please try again later.",
    //     icon: "error",
    //   });
    // }
  };

  return (
    <div className="max-w-7xl mx-auto p-5">
      <div className=" grid gap-4 main lg:grid lg:grid-cols-2 my-16 items-center">
        <div className="first flex flex-col gap-8">
          <div className="flex items-center gap-4">
            <span className="px-4 py-3 bg-cyan-500 rounded-md text-xl">
              <FaLocationDot />
            </span>
            <span>
              Panchashil building, Durga Mata Mandir Road, Opp. Nutan Hindi high
              school, Katemanivali, Kalyan East, Maharashtra 421306
            </span>
          </div>
          <div className="flex items-center gap-4">
            <span className="px-4 py-3 bg-cyan-500 rounded-md  text-xl">
              <MdEmail />
            </span>
            <span>saprem.ngo@gmail.com</span>
          </div>
          <div className="flex items-center gap-4">
            <span className="px-4 py-3 bg-cyan-500 rounded-md  text-xl">
              <FaPhone />
            </span>
            <span>+91 8898878809</span>
            <span>+91 8898861112</span>

          </div>
          <div className="flex space-x-4  mt-1 lg:mt-0">
            <Link
              to="https://www.facebook.com/sharer/sharer.php?u=http%3A%2F%2Fsapremngo.in"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaFacebook className="text-3xl text-cyan-500" />
            </Link>

            <Link
              to="https://www.youtube.com/channel/UCtKjoV2rSaO7evrb4gvQZFg"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaYoutube className="text-3xl text-cyan-500" />
            </Link>
            <Link
              to="https://www.instagram.com/saprem_ngo"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaInstagram className="text-3xl text-cyan-500" />
            </Link>
          </div>
        </div>

        <div>
          <iframe
            className="w-full h-[50vh]"
            title="saprem ngo"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3767.218564889955!2d73.13762907490836!3d19.229304047142538!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be79434161d2165%3A0x2c9d5b1aaddcf910!2sSAPREM%20NGO!5e0!3m2!1sen!2sin!4v1736171911277!5m2!1sen!2sin"
            allowfullscreen=""
            loading="lazy"
            referrerpolicy="no-referrer-when-downgrade"
          ></iframe>
        </div>
      </div>
    </div>
  );
};

export default Contact;
