import React from "react";
import { useNavigate } from "react-router-dom";
import p1 from "../assets/projects/p1.png";
import p2 from "../assets/projects/m1.png";
import p3 from "../assets/projects/j1.png";
import p4 from "../assets/projects/1.png";
import p5 from "../assets/projects/w1.png";
import p6 from "../assets/projects/o1.png";
import p7 from "../assets/projects/h1.png";
import s from "../assets/projects/past/b4.png";
import e from "../assets/projects/past/b2.png";
import b from "../assets/projects/past/s6.png";
import f from "../assets/projects/family2.png";
import bring from "../assets/projects/bring.JPG";
import jaldhara from "../assets/projects/Jaldhara1.jpeg";




const projects = [

  {
       title: "Bring a Smile English Medium School",
       image: b,
       value: "SCHOOL",
      component: "School",
     },

     {
      title: "Bring A Smile school for special children",
      image: bring,
      value: "CHILDREN",
     component: "children",
    },
  
 

  {
    title: "Family counseling centre , Alibaugh.",
    image: f,
    value: "Family",
    component: "Family",
  },
  {
    title: "Smiling Bus Project",
    image: s,
    value: "BUS",
    component: "Bus",
  },
  {
    title: "Access to Education",
    image: e,
    value: "EDUCATION",
    component: "Education",
  },
  {
    title: "DORF KETAL JUNGLE CUP",
    image: p1,
    value: "JUNGLE",
    component: "Jungle",
  },
  {
    title: "Water cup",
    image: p5,
    value: "WATER",
    component: "Water",
  },
  {
    title: "MOBILE HEALTH CLINIC PROJECT",
    image: p7,
    value: "HEALTH",
    component: "MobileHealth",
  },
  {
    title: "DORF KETAL MANGO",
    image: p2,
    value: "MANGO",
    component: "Mango",
  },
  
  {
    title: "JALDHARA PROJECT SHENDEWADI",
    image: jaldhara,
    value: "JALDHARA",
    component: "Jaldhara",
  },
  {
    title: "PRIDE - BRIGHT FUTURE FOR TRANSGENDER GROUP",
    image: p4,
    value: "PRIDE",
    component: "Pride",
  },
  
  {
    title: "KELUNGAN OLD AGE HOME PROJECT",
    image: p6,
    value: "HOME",
    component: "OldAgeHome",
  },
 
];

const PresentProject = () => {
  const navigate = useNavigate();

  const handleCardClick = (value) => {
    navigate(`/projects/${value.toLowerCase()}`);
  };

  return (
    <div className="max-w-7xl mx-auto px-4 py-8">
      <h1 className="text-3xl font-bold text-center mb-6 text-gray-800">
        Current Projects
      </h1>

      {/* Cards */}
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
        {projects.map((project) => (
          <div
            key={project.value}
            onClick={() => handleCardClick(project.value)}
            className="cursor-pointer bg-white shadow-md rounded-lg overflow-hidden transition-transform transform hover:scale-105"
          >
            <img
              src={project.image}
              alt={project.title}
              className="w-full h-[40vh] object-cover"
            />
            <div className="p-4">
              <h2 className="text-lg font-bold text-gray-800">
                {project.title}
              </h2>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default PresentProject;
