import React, { useState } from "react";
import Logo from "../../../../assets/projects/past/blogo.png";
import p1 from "../../../../assets/projects/bring.JPG";
import p2 from "../../../../assets/projects/mr1.JPG";
import p3 from "../../../../assets/projects/mr2.JPG";
import p4 from "../../../../assets/projects/mr3.JPG";
import p5 from "../../../../assets/projects/mr4.JPG";
import p6 from "../../../../assets/projects/mr6.JPG";
import p7 from "../../../../assets/projects/mr7.JPG";
import p8 from "../../../../assets/projects/mr8.JPG";

const Children = () => {
  const images = [p1, p2, p3, p4, p5, p6, p7, p8];
  const [selectedImage, setSelectedImage] = useState(null);

  const openModal = (image) => setSelectedImage(image);
  const closeModal = () => setSelectedImage(null);
  return (
    <div className="bg-gray-50 min-h-screen py-10 px-5">
      {/* Header Section */}
      <div className="max-w-6xl mx-auto bg-white shadow-md rounded-lg p-6">
        <div className="flex flex-col lg:flex-row items-center justify-between">
          {/* Logo */}
          <img src={Logo} alt="Company Logo" className="h-16 w-auto" />
          {/* Title */}
          <h1 className="text-2xl lg:text-3xl font-bold text-orange-600 text-center">
            Bring A Smile school for special children.          </h1>
        </div>
      </div>

      {/* Content Section */}
      <div className="max-w-6xl mx-auto bg-white shadow-md rounded-lg mt-8 p-6">
        {/* Introduction */}
        <h2 className="text-xl lg:text-2xl font-semibold text-gray-700 mb-4">
          About the Project
        </h2>
        <p className="text-gray-600 leading-7">
          The School was taken over in the year 2009 by SAPREM NGO. Earlier it was run and managed by Disha Foundation but Kalyan Dombivali Municipal Corporation (KDMC) handed over the Class Rooms to the organization and it is being actively managed by the organization with the Trained Teachers and Staff.

          Now, it is shifted in K B Veera School at Dombivali East in Two Class Rooms and 32 Special Children are given admission. Dorf Ketal Private (I) Limited has supported for Renovation of the School and now it is the best Special School in Thane District. A CSR initiatives of the “Dorf Ketal Chemicals (I) Pvt. Ltd.” Ltd and also sponsoring the school’s functioning under its CSR initiative.
        </p>
        <h2 className="text-xl lg:text-2xl font-semibold text-gray-700 mt-8">
          Mental Retardation:
        </h2>
        <p className="text-gray-600 leading-7 mt-4">
          This is a condition in which there is delay or deficiency in all aspects of development, i.e. there is global and noticeable deficiency in the development of motor,cognitive, social, and language functions. This is the commonest form of developmental disability. In many ways, mental retardation is also representative of developmental disabilities in general, in its causation, nature, and care.
        </p>



        <h2 className="text-xl lg:text-2xl font-semibold text-gray-700 mt-8">
          Degrees of Mental Retardation:
        </h2>
        <ul className="list-disc list-inside text-gray-600 mt-4 space-y-2">

          <li>Mild Mental Retardation (3-6 Children against 1000 population) -  IQ between 50-55 & 70</li>
          <li>Moderate Mental Retardation (2 Children against 1000 population) - IQ between 35-40 & 50-55</li>
          <li>Severe Mental Retardation (1.3 Children against 1000 population) - IQ between 20-25 & 35-40</li>
          <li>Profound Mental Retardation (0.4 Children against 1000 population) -  IQ below 20 or 25</li>

        </ul >

        <h2 className="text-xl lg:text-2xl font-semibold text-gray-700 mt-8">
          Mission        </h2>

        <p className="text-gray-600 leading-7 mt-4">

          Regulate the Mentally Challenged children by initiating various activities and them to live as a complete human being
        </p>


        <h2 className="text-xl lg:text-2xl font-semibold text-gray-700 mt-8">
          Objectives

        </h2>

        <ul className="list-disc list-inside text-gray-600 mt-4 space-y-2">

          <li>To initiate activities related to mental health for the differently able children of Dombivali.</li>
          <li>To take up awareness campaign among the community to prevent the disability.</li>
          <li> Try to rehabilitate the disabled where such children will do income generation activities.
          </li>
          <li> To plan out the recreational facilities for them which will give them the Mental and Physical stability.
          </li>
          <li>
            To organize various training programmes for the parents this will reduce their stress and will able to cope up with the disease.

          </li>

        </ul>





        {/* Project Outcomes */}
        <h2 className="text-xl lg:text-2xl font-semibold text-gray-700 mt-8">
          Replication (it can be done) :

        </h2>
        <p className="text-gray-600 leading-7 mt-4">
          Mental health is a vast subject and SAPREM would make the people aware about various issues of mental health and focus would be given to Mental Retardation. General people would be made sensitized about the issues and such schools would be started at various places as per the need and demand by the people.


        </p>

        <h2 className="text-xl lg:text-2xl font-semibold text-gray-700 mt-8">
        Deliverables (What we do):


        </h2>

        {/* Conclusion */}
        <p className="text-gray-600 leading-7 mt-6">
        Mental Retardation refers to substantial limitations in present functioning. It is characterized by significantly sub-average intellectual functioning, existing concurrently with related limitations in two or more of the following adaptive skill areas: Communication, Self care, Home living, Health and Safety, Functional Academics, Leisure and Work.

Above areas are focused during the Day time as it is a Day Care School.


        </p>
      </div>
      <br />
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
        {images.map((image, index) => (
          <div
            key={index}
            className="relative group cursor-pointer"
            onClick={() => openModal(image)}
          >
            <img
              src={image}
              alt={`Project ${index + 1}`}
              className="w-full h-full object-cover rounded-lg shadow-md transition-transform duration-300 transform group-hover:scale-105"
            />
          </div>
        ))}
      </div>

      {selectedImage && (
        <div
          className="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center z-50"
          onClick={closeModal}
        >
          <div className="relative max-w-3xl w-full p-4">
            <img
              src={selectedImage}
              alt="Selected"
              className="w-full max-h-[80vh] object-contain rounded-lg"
            />
            <button
              onClick={closeModal}
              className="absolute top-4 right-4 bg-white text-black rounded-full p-2 text-lg font-bold shadow-md hover:shadow-lg"
            >
              ✕
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Children;
