import React from "react";
import { FaUsers, FaTree, FaWater, FaHandsHelping } from "react-icons/fa";

const StatsSection = () => {
  return (
    <div className="py-16 fon bg-gray-100">
      <div className="max-w-6xl mx-auto px-4 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
        <div className="flex fon flex-col items-center bg-white p-6 rounded-lg shadow-lg hover:shadow-xl transition-shadow duration-300">
          <FaUsers className="text-4xl text-green-500 mb-4" />
          <h3 className=" fon text-xl font-semibold text-gray-800">
            No of Beneficiaries
          </h3>
          <p className="text-3xl fon font-bold text-gray-900"> 24 lac</p>
        </div>

        <div className="flex flex-col items-center bg-white p-6 rounded-lg shadow-lg hover:shadow-xl transition-shadow duration-300">
          <FaTree className="text-4xl text-green-600 mb-4" />
          <h3 className="text-xl fon font-semibold text-gray-800">
            Tree Plantations
          </h3>
          <p className="text-3xl fon marker:font-bold text-gray-900">276,000</p>
        </div>

        <div className="flex flex-col items-center bg-white p-6 rounded-lg shadow-lg hover:shadow-xl transition-shadow duration-300">
          <FaWater className="text-4xl text-blue-500 mb-4" />
          <h3 className="text-xl fon font-semibold text-gray-800">
            Water Collection
          </h3>
          <p className="text-2xl font-bold fon text-gray-900">
            33,17,38,568 ltr
          </p>
        </div>

        <div className="flex flex-col items-center bg-white p-6 rounded-lg shadow-lg hover:shadow-xl transition-shadow duration-300">
          <FaHandsHelping className="text-4xl text-yellow-500 mb-4" />
          <h3 className="text-xl font-semibold fon text-gray-800">
            Volunteers
          </h3>
          <p className="text-3xl font-bold fon text-gray-900">5400</p>
        </div>
      </div>
    </div>
  );
};

export default StatsSection;
