import React from "react";
import { Link } from "react-router-dom";

const Policies = () => {
  return (
    <div className="p-8">
      <p className="text-center font-bold text-3xl mb-8">Policies & Act</p>
      <div className="flex flex-col items-center space-y-4">
        {/* First PDF Link as a Button */}
        <Link
          to={
            "http://sapremngo.in/wp-content/superstitions_eradication_act.pdf"
          }
          target="_blank"
          className="bg-blue-600 text-white py-2 px-6 rounded-lg shadow-lg hover:bg-blue-700 transition duration-300"
        >
          Superstitions Eradication Act
        </Link>

        {/* Second PDF Link as a Button */}
        <Link
          to={
            "http://sapremngo.in/wp-content/Positions_at_CSR_Knowledge_Centre_6July15.pdf"
          }
          target="_blank"
          className="bg-green-600 text-white py-2 px-6 rounded-lg shadow-lg hover:bg-green-700 transition duration-300"
        >
          Positions at CSR Knowledge Centre (6 July 2015)
        </Link>

        {/* Third PDF Link as a Button */}
        <Link
          to={
            "http://sapremngo.in/wp-content/Revised_DPE_Guiedelines_1st_April_2013.pdf"
          }
          target="_blank"
          className="bg-orange-600 text-white py-2 px-6 rounded-lg shadow-lg hover:bg-orange-700 transition duration-300"
        >
          Revised DPE Guidelines (1st April 2013)
        </Link>
      </div>
    </div>
  );
};

export default Policies;
