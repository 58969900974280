import React from "react";
import Slider from "../components/comman/Slider";
import WhyUs from "../components/core/home/WhyUs";
// import Welcome from "../components/core/home/Welcome";
import KnowAboutUs from "../components/core/home/KnowAboutUs";
import Features from "../components/core/home/Features";
import StatsSection from "../components/core/home/StateSection";
import Client from "../components/core/home/Client";
import Event from "../pages/EventPage";
import Blog from "../pages/BlogPage";

const Home = () => {
  return (
    <div>
      <Slider />

      <StatsSection />
      {/* <Welcome /> */}
      <KnowAboutUs />
      <WhyUs />
      <Features />
      <Client />
      <br />
      <br />
      <Event />

      <Blog />

      {/* <Team /> */}
    </div>
  );
};

export default Home;
