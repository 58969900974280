import React, { useState } from "react";
import p1 from "../../../../assets/projects/h1.png";
import p2 from "../../../../assets/projects/h2.png";
import p3 from "../../../../assets/projects/h3.png";
const MobileHealth = () => {
  const images = [p1, p2, p3];
  const [selectedImage, setSelectedImage] = useState(null);

  const openModal = (image) => setSelectedImage(image);
  const closeModal = () => setSelectedImage(null);
  return (
    <div className="container mx-auto p-6">
      <div className="text-center mb-8">
        <h1 className="text-4xl font-bold uppercase">MOBILE HEALTH CLINIC</h1>
        <p className="text-xl mt-4">
          Health is the key area where we have identified the need for a Mobile
          Health Clinic Van. The new Ambulance Van has been modified into a
          Mobile Health Clinic and is now providing basic health services to the
          Andheri, Mumbai community. Daily, 180-200 patients are checked and
          given free medications for their health needs, and they are referred
          to tertiary care centers for long-term treatment. Almost 3000 patients
          have benefited in the last 60 days, thanks to our expert Medical
          Officers and Nursing Staff.
        </p>
      </div>

      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
        {images.map((image, index) => (
          <div
            key={index}
            className="relative group cursor-pointer"
            onClick={() => openModal(image)}
          >
            <img
              src={image}
              alt={`Project ${index + 1}`}
              className="w-full h-64 object-cover rounded-lg shadow-md transition-transform duration-300 transform group-hover:scale-105"
            />
          </div>
        ))}
      </div>

      {selectedImage && (
        <div
          className="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center z-50"
          onClick={closeModal}
        >
          <div className="relative max-w-3xl w-full p-4">
            <img
              src={selectedImage}
              alt="Selected"
              className="w-full max-h-[80vh] object-contain rounded-lg"
            />
            <button
              onClick={closeModal}
              className="absolute top-4 right-4 bg-white text-black rounded-full p-2 text-lg font-bold shadow-md hover:shadow-lg"
            >
              ✕
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default MobileHealth;
