import React from "react";
import Navbar from "./components/comman/Navbar";
import { Route, Routes } from "react-router-dom";
import Home from "./pages/Home";
import ScrollToTop from "./components/core/ScrollToTop";
import Footer from "./components/comman/Footer";
import About from "./pages/About";
import Contact from "./pages/Contact";
import Donate from "./pages/Donate";
import Team from "./pages/Team";
import Gallery from "./pages/Gallery";
import News from "./pages/News";
import Policies from "./pages/Policies";
import EducationRight from "./pages/EducationRight";
import Reports from "./pages/Reports";
import PastProject from "./pages/PastProject";
import SubNav from "./components/comman/SubNav";
import EventPage from "./pages/EventPage";
import BlogPage from "./pages/BlogPage";
import PresentProject from "./pages/PresentProject";
import Anganwadi from "./components/core/project/past/Anganwadi";
import CommunityHall from "./components/core/project/past/CommunityHall";
import MobileHealth from "./components/core/project/Present/MobileHealth";
import Bus from "./components/core/project/Present/Bus";
import Rescue from "./components/core/project/past/Rescue";
import Udaan from "./components/core/project/past/Udaan";
import Education from "./components/core/project/Present/Education";
import School from "./components/core/project/Present/School";
import Jungle from "./components/core/project/Present/Jungle";
import Mango from "./components/core/project/Present/Mango";
import Jaldhara from "./components/core/project/Present/Jaldhara";
import Pride from "./components/core/project/Present/Pride";
import Water from "./components/core/project/Present/Water";
import OldAgeHome from "./components/core/project/Present/OldAgeHome";
import Balwadi from "./components/core/project/past/Balwadi";
import Health from "./components/core/project/past/Health";
import Family from "./components/core/project/Present/Family";
import Children from "./components/core/project/Present/Children";
import { FaChildren } from "react-icons/fa6";
const App = () => {
  return (
    <div>
      <SubNav />

      <Navbar />
      <div className="mt-32">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/team" element={<Team />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/gallery" element={<Gallery />} />
          <Route path="/donate" element={<Donate />} />
          <Route path="/news" element={<News />} />
          <Route path="/policies" element={<Policies />} />
          <Route path="/education-right" element={<EducationRight />} />
          <Route path="/reports" element={<Reports />} />
          <Route path="/pastProjects" element={<PastProject />} />
          <Route path="/presentProjects" element={<PresentProject />} />
          <Route path="/event" element={<EventPage />} />
          <Route path="/blog" element={<BlogPage />} />
          <Route path="/projects/anganwadi" element={<Anganwadi />} />
          <Route path="/projects/community" element={<CommunityHall />} />
          <Route path="/projects/health" element={<MobileHealth />} />
          <Route path="/projects/bus" element={<Bus />} />
          <Route path="/projects/rescue" element={<Rescue />} />
          <Route path="/projects/udaan" element={<Udaan />} />
          <Route path="/projects/education" element={<Education />} />
          <Route path="/projects/school" element={<School />} />
          <Route path="/projects/jungle" element={<Jungle />} />
          <Route path="/projects/mango" element={<Mango />} />
          <Route path="/projects/jaldhara" element={<Jaldhara />} />
          <Route path="/projects/pride" element={<Pride />} />
          <Route path="/projects/water" element={<Water />} />
          <Route path="/projects/home" element={<OldAgeHome />} />
          <Route path="/projects/health" element={<MobileHealth />} />
          <Route path="/projects/balwadi" element={<Balwadi />} />
          <Route path="/projects/health-mumbai" element={<Health />} />
          <Route path="/projects/family" element={<Family />} />
          <Route path="/projects/children" element={<Children />} />

          

        </Routes>
      </div>

      <Footer />
      <ScrollToTop />
    </div>
  );
};

export default App;
