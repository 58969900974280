import React, { useState } from "react";
import g1 from "../assets/gallery/g1.jpg";
import g2 from "../assets/gallery/g2.jpg";
import g3 from "../assets/gallery/g3.jpg";
import g4 from "../assets/gallery/g4.jpg";
import g5 from "../assets/gallery/g5.jpg";
import g6 from "../assets/gallery/g6.jpg";
import g7 from "../assets/gallery/g7.jpg";
import g8 from "../assets/gallery/g8.jpg";
import g9 from "../assets/gallery/g9.jpg";
import g10 from "../assets/gallery/g10.jpg";
import g11 from "../assets/gallery/g11.jpg";
import g12 from "../assets/gallery/g12.jpg";
import g13 from "../assets/gallery/g13.jpg";
import g14 from "../assets/gallery/g14.jpg";
import g15 from "../assets/gallery/g15.jpg";
import g16 from "../assets/gallery/g16.jpg";
import g17 from "../assets/gallery/g17.jpg";
import g18 from "../assets/gallery/g18.jpg";
import g19 from "../assets/gallery/g19.jpg";
import g20 from "../assets/gallery/g20.jpg";
import g21 from "../assets/gallery/g21.jpg";
const Gallery = () => {
  const allImages = [
    g1,
    g2,
    g3,
    g4,
    g5,
    g6,
    g7,
    g8,
    g9,
    g10,
    g11,
    g12,
    g13,
    g14,
    g15,
    g16,
    g17,
    g18,
    g19,
    g20,
    g21,
  ];

  // State to manage the currently visible images
  const [visibleImages, setVisibleImages] = useState(allImages.slice(0, 6));

  // Load more images on button click
  const loadMoreImages = () => {
    const currentIndex = visibleImages.length;
    setVisibleImages(allImages.slice(0, currentIndex + 6));
  };

  return (
    <div>
      <div className="max-w-7xl mx-auto p-5">
        <h1 className="text-2xl font-bold text-center mb-8">Gallery</h1>

        {/* Gallery Grid */}
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-6">
          {visibleImages.map((image, index) => (
            <div key={index} className="relative overflow-hidden">
              <img
                src={image}
                alt="not found"
                className="w-full h-auto object-cover rounded-lg shadow-lg"
              />
            </div>
          ))}
        </div>

        {/* "Read More" Button */}
        {visibleImages.length < allImages.length && (
          <div className="text-center mt-6">
            <button
              onClick={loadMoreImages}
              className="bg-blue-600 text-white py-2 px-6 rounded-lg hover:bg-blue-700 transition duration-300"
            >
              Read More
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default Gallery;
