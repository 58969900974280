import React from "react";
import { FaCheckCircle } from "react-icons/fa";
import c1 from "../assets/common/c1.jpg";
import Team from "../components/core/home/Team";
import map from "../assets/map.png";
const data = [
  {
    agency: "UNFPA – IPD Society",
    projects: ["Women & Community Empowerment"],
    target: "KDMC Slum",
    period: "05 Yrs",
    startDate: "Oct 2003-2008",
  },
  {
    agency: "UNFPA – IPD Society",
    projects: ["Women & Community Empowerment"],
    target: "UMC Slum",
    period: "05 Yrs",
    startDate: "Oct 2003-2008",
  },
  {
    agency: "KDMC",
    projects: [
      "HIV Project",
      "DAAN",
      "Family Counselling Centre",
      "Female Feticide",
    ],
    target: "Rural & Urban KDMC",
    period: ["1 Mth", "1 Yr", "1 Yr", "1 Week"],
    startDate: ["Dec 2002 – 2003", "April 2004", "– do –", "Dec 2004"],
  },
  {
    agency: "MSSWB",
    projects: ["Rural General Awareness Programme", "Creach"],
    target: "Rural Population",
    period: ["1 Week", "Continuous"],
    startDate: ["Jan 2004", "March 2004"],
  },
  {
    agency: "State Government",
    projects: ["Mahatma Phule Shikshan Hami Yojna"],
    target: "Rural Population",
    period: "Continuous",
    startDate: "April 2003",
  },
  {
    agency: "Child India Foundation",
    projects: ["Pre Intervention Survey"],
    target: "Ulhasnagar",
    period: "1 Mth",
    startDate: "April 2001",
  },
  {
    agency: "State Police Department",
    projects: ["Training Programme on Stress Management"],
    target: "Kalyan",
    period: "1 Day",
    startDate: "Nov 2002",
  },
  {
    agency: "Corporate",
    projects: ["Ranbaxy – HIV Campaign"],
    target: "KDMC",
    period: "1 Mth",
    startDate: "Nov 2002",
  },
  // New Entries
  {
    agency: "AVERT Society",
    projects: [
      "HIV/ AIDS (PINJARA Project)",
      "Targeted Intervention, Central Jail, Kalyan",
    ],
    target: "Central Jail, Kalyan",
    period: "3 Yr",
    startDate: "Dec 2005-2008",
  },
  {
    agency: "Maharashtra State AIDS Control Society (MSACS)",
    projects: ["HIV/AIDS – Migrant Workers Project"],
    target: "Panvel, Dist: Raigad, Maharashtra",
    period: "2 Yr",
    startDate: "2008-2010",
  },
  {
    agency: "Mumbai Metropolitan Region Development Authority (MMRDA)",
    projects: ["Nirmal MMR Abhiyan – Community Sanitation Block Construction"],
    target: "Slums of KDMC & UMC",
    period: "3 Yr",
    startDate: "2008-2012",
  },
  {
    agency: "Catholic Health Association of India (CHAI)",
    projects: [
      "DOT (Direct Observations & Treatment) – Eradication of Tuberculosis",
    ],
    target: "Thane District – Urban & Rural",
    period: "2 Yr",
    startDate: "2010-2012",
  },
  {
    agency: "Mumbai Smiles",
    projects: ["Khushi Canteen – Subsidized Food for Staff & Visitors"],
    target: "Andheri, Mumbai",
    period: "2 Yr",
    startDate: "2010-2012",
  },
  {
    agency: "SONRISAS De Bombay (Spain-based NGO)",
    projects: [
      "Balwadi Project – Pre-school & Healthy Habits Education",
      "Future Smile – Sponsorships for Marginalized Students",
      "Health Project – Income Generation Training for Distressed Females",
      "SEED Project – Skill Development Training",
    ],
    target: "Mumbai & Maharashtra",
    period: "4 Yr",
    startDate: "2010-Till Date",
  },
  {
    agency: "Women & Child Development Department, Govt. of Maharashtra",
    projects: ["Family Counseling – Special Cell for Women & Children"],
    target: "Alibaug, Dist: Raigad",
    period: "3 Yr",
    startDate: "2011-Till Date",
  },
  {
    agency: "SAPREM",
    projects: [
      "English Medium School – Pre-primary Rural School",
      "Bring A Smile M.R. School – Pre-primary Rural School",
      "Ideal Village Project – Integrated Development",
    ],
    target: "Sai Nagar, Shahpur",
    period: ["Long Term", "5 Yr"],
    startDate: ["2012", "Initiated in 2012"],
  },
  {
    agency: "Smile Foundation",
    projects: ["STEP Project – Skill Development for Youth"],
    target: "Mumbai",
    period: "1 Yr",
    startDate: "2015",
  },
  {
    agency: "Women & Child Development Department, Govt. of Maharashtra",
    projects: ["Family Counselling – Special Cell for Women & Children"],
    target: "Alibag, Dist: Raigad",
    period: "4 Yr",
    startDate: "2010 – Till date",
  },
  {
    agency: "SAPREM",
    projects: ["English Medium School – Pre-primary School in Rural area"],
    target: "Sai Nagar, Shahpur",
    period: "Long Term",
    startDate: "Started in 2012",
  },
  {
    agency: "SAPREM",
    projects: ["Bring A Smile M.R. School – Pre-primary School in Rural area"],
    target: "Dombiwali",
    period: "Long Term",
    startDate: "Initiated in 2010",
  },
  {
    agency: "SAPREM",
    projects: ["Ideal Village Project – Integrated Development"],
    target: "Birwadi, Shahpur",
    period: "5 Yr",
    startDate: "Initiated in 2012",
  },
];

const About = () => {
  return (
    <div>
      <div>
        <img
          src={c1}
          alt="not found"
          className="w-screen h-auto object-cover"
        />

        <div className="bg-gray-50 py-10 px-4 md:px-16">
          {/* Background Section */}
          <div className="bg-white shadow-lg rounded-lg p-6 md:p-10 mb-8">
            <h2 className="text-3xl font-bold mb-6 text-center text-blue-600">
              Background
            </h2>
            <p className="text-gray-700 leading-relaxed mb-4">
              SAPREM is an Non Government Organization working for the societal
              development in Rural and Urban communities since 1998 with focus
              on Health, Education and Community Empowerment programmes. Our
              activities scattered to Mumbai, Thane, Raigad, Ahemadnagar, Nasik, and Jalana District of
              Maharashtra.
            </p>
            <p className="text-gray-700 leading-relaxed mb-4">
              We got registered under Society Registration Act and Public Trust
              Act in 1999 and also Central Government has given us FCRA
              certification in 2004.
            </p>
            <p className="text-gray-700 leading-relaxed mb-4">
              We have a strong committee members and a core team of 5 trained
              personnel and good number of volunteers from different streams.
              The professionals from the field of Social Work, Management,
              Administration and Documentation head the team.
            </p>
            <p className="text-gray-700 leading-relaxed">
              We strongly believe and have expertise in preparing software i.e.
              attitude change, people’s participation, ownership, community
              motivation prior to provision of any hardware. In the present
              intervention we believe in approach of demand generation than
              service provider. It has been the past experience that the
              approach of service provider does not bring the effectiveness in
              overall developmental efforts. However though initially it takes
              time and efforts to have software in place but towards the end the
              effectiveness increases to its optimum level.
            </p>
          </div>

          {/* Vision Section */}
          <div className="bg-white shadow-lg rounded-lg p-6 md:p-10 mb-8">
            <h3 className="text-2xl font-bold mb-4 text-center text-blue-600">
              Vision of SAPREM
            </h3>
            <p className="text-gray-700 leading-relaxed text-center">
              Identify the impact of socio-economic issues and strive for
              social, educational and economic well-being of the human being in
              the society by ensuring community participation through
              participatory methods.
            </p>
          </div>

          {/* Mission Section */}
          <div className="bg-white shadow-lg rounded-lg p-6 md:p-10 mb-8">
            <h3 className="text-2xl font-bold mb-4 text-center text-blue-600">
              Mission Statement of the Organization
            </h3>
            <p className="text-gray-700 leading-relaxed text-center">
              Social Aspiration for Participatory Reforms by Evolved Manpower
            </p>
          </div>

          {/* Areas of Intervention Section */}
          {/* <div className="bg-white shadow-lg rounded-lg p-6 md:p-10">
            <h3 className="text-2xl font-bold mb-6 text-center text-blue-600">
              Area of Intervention
            </h3>
            <div className="grid grid-cols-1 md:grid-cols-4 gap-6 text-center">
              <div className="flex flex-col items-center">
                <FaHeartbeat className="text-red-500 text-5xl mb-3" />
                <h4 className="text-lg font-semibold text-gray-800">Health</h4>
              </div>
              <div className="flex flex-col items-center">
                <FaBook className="text-green-500 text-5xl mb-3" />
                <h4 className="text-lg font-semibold text-gray-800">
                  Education
                </h4>
              </div>
              <div className="flex flex-col items-center">
                <FaUsers className="text-blue-500 text-5xl mb-3" />
                <h4 className="text-lg font-semibold text-gray-800">
                  Community Empowerment
                </h4>
              </div>
              <div className="flex flex-col items-center">
                <FaChalkboardTeacher className="text-yellow-500 text-5xl mb-3" />
                <h4 className="text-lg font-semibold text-gray-800">
                  Training
                </h4>
              </div>
            </div>
          </div> */}
        </div>

        <div className="bg-gray-50 py-10 px-4 md:px-16">
          {/* Objectives Section */}
          <div className="bg-white shadow-lg rounded-lg p-6 md:p-10 mb-8">
            <h2 className="text-3xl font-bold mb-6 text-center text-blue-600">
              Objectives of the Organization
            </h2>
            <ul className="list-none space-y-4 text-gray-700">
              {[
                "To undertake various projects for the development of socially, economically and educationally weak sections.",
                "To provide health education for improvement in health standards of each section of the society and also provide facilities to maintain general good health.",
                "“Water is life” thus providing safe water so that drinkable water is available to the society; also to create awareness and ensure that the available water does not get contaminated and create facilities of cleanliness in surroundings eg individual toilet, construction of drainage etc.",
                "To undertake constructive projects for the developments of unorganized labourers.",
                "To create public awareness so that women and children get an equal status in the society and also run special programmes for their development.",
                "To provide information to society about social, economical and educational government schemes and also law related schemes and create facilities so that they can avail the schemes.",
                "To execute various professional, personal development and health-oriented training programmes.",
                "To undertake rural and urban ‘alternative developmental’ programmes.",
                "To organize tribal and backward people, create public opinion against discrimination on the basis of caste and creed and to ensure that they will continuously avail all the human rights provided by Indian constitution.",
                "To involve people participation when any program for societal development (eg: social, economical) is executed.",
                "‘Social Research’ is a means to bring about development of the society. Therefore encourage social research to every program and project.",
                "To maintain contacts with individuals, organization, educational institutions and community based organization (CBO’s) involved in social development and also strive to bring them together by which different individuals and organizations can be united and steps towards “Integrated Development” will be taken.",
                "To work towards putting an end to juvenile crime by organizing such programmes and also organize programmes for rehabilitating juvenile criminals and adult criminals and also help governmental and non-governmental organization in this aspect.",
                "To create social system for eradication of superstition, misbelief and bad behaviour from the society.",
                "To abolish myths and misunderstanding about mental illness and create awareness among the people affected by this illness.",
                "To undertake formal and informal educational projects for educational development of the society.",
              ].map((objective, index) => (
                <li key={index} className="flex items-start">
                  <FaCheckCircle className="text-green-500 mt-1 mr-3" />
                  <p className="leading-relaxed">{objective}</p>
                </li>
              ))}
            </ul>
          </div>

          {/* Coverage of Activities Section */}
          <div className="bg-white shadow-lg rounded-lg p-6 md:p-10">
            <h3 className="text-2xl font-bold mb-4 text-center text-blue-600">
              Coverage of Areas
            </h3>
            <div className="flex flex-col md:flex-row justify-center space-y-4 md:space-y-0 md:space-x-10 text-center">
              <img src={map} alt="not found" />
            </div>
          </div>
        </div>
      </div>
      <Team />
    </div>
  );
};

export default About;

{
  /* <div className="container mx-auto px-4 py-8">
<div className="overflow-x-auto">
  <table className="w-full border border-gray-300">
    {/* Table Header */
}
// <thead className="bg-blue-500 text-white">
//   <tr>
//     <th className="px-4 py-2 border">Agency</th>
//     <th className="px-4 py-2 border">Targeted Population</th>
//     <th className="px-4 py-2 border">Period</th>
//     <th className="px-4 py-2 border">Start Date</th>
//   </tr>
// </thead>

// /
// </table>
// </div>
// </div> */}
