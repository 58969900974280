import React from "react";
import { useNavigate } from "react-router-dom";
import bal1 from "../assets/projects/past/bal.jpg";
import c from "../assets/projects/past/c1.png";
import h from "../assets/projects/past/health1.png";
import s from "../assets/projects/past/b4.png";
import r from "../assets/projects/past/r3.png";
import u from "../assets/projects/past/u.png";
import e from "../assets/projects/past/b2.png";
import b from "../assets/projects/past/s6.png";
import a from "../assets/projects/past/a2.png";
const projects = [
  {
    title:
      "Balwadi project: Mumbai",
    image: bal1,
    value: "Balwadi",
    component: "Balwadi",
  },
  {
    title:
      "IRCON Anganwadi improvement project, Jalana",
    image: a,
    value: "Anganwadi",

    component: "Anganwadi",
  },
  {
    title: "IRCON Community hall project",
    image: c,
    value: "COMMUNITY",
    component: "CommunityHall",
  },
  {
    title: "Rescue (Anti human trafficking) project",
    image: r,
    value: "RESCUE",
    component: "Rescue",
  },
  {
    title: "Udaan shelter ",
    image: u,
    value: "UDAAN",
    component: "Udaan",
  },
  {
    title: "Health project, Mumbai",
    image: h,
    value: "health-mumbai",
    component: "Health",
  },
  
  
  
  // {
  //   title: "Access to Education",
  //   image: e,
  //   value: "EDUCATION",
  //   component: "Education",
  // },
  // {
  //   title: "Bring a Smile English Medium School",
  //   image: b,
  //   value: "SCHOOL",
  //   component: "School",
  // },
];

const PastProject = () => {
  const navigate = useNavigate();

  const handleCardClick = (value) => {
    navigate(`/projects/${value.toLowerCase()}`);
  };

  return (
    <div className="max-w-7xl mx-auto px-4 py-8">
      <h1 className="text-3xl font-bold text-center mb-6 text-gray-800">
        Past Projects
      </h1>

      {/* Cards */}
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
        {projects.map((project) => (
          <div
            key={project.value}
            onClick={() => handleCardClick(project.value)}
            className="cursor-pointer bg-white shadow-md rounded-lg overflow-hidden transition-transform transform hover:scale-105"
          >
            <img
              src={project.image}
              alt={project.title}
              className="w-full h-[40vh] object-cover"
            />
            <div className="p-4">
              <h2 className="text-lg font-bold text-gray-800">
                {project.title}
              </h2>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default PastProject;
