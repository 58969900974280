import React from "react";
import { Link } from "react-router-dom"; // Import Link
import { FaFacebook, FaTwitter, FaYoutube, FaInstagram } from "react-icons/fa";
const Footer = () => {
  return (
    <footer className="bg-gray-900 text-gray-300 py-10">
      <div className="max-w-7xl p-5 mx-auto grid grid-cols-1 md:grid-cols-4 gap-8">
        {/* Logo and Organization Info */}
        <div className="col-span-1">
          {/* <img src="path/to/logo.png" alt="" className="w-32 mb-4" /> */}
          <h4 className="text-yellow-500 text-lg mb-4">Main Office</h4>
          <p>
            Panchashil building, Durga Mata Mandir Road, Opp. Nutan Hindi high
            school, Katemanivali, Kalyan East, Maharashtra 421306
          </p>
        </div>

        {/* Contact Information */}
        <div>
          <h4 className="text-yellow-500 text-lg mt-4">Phone</h4>
          <p>+91 8898878809</p>
          <p>+91 8898861112</p>
          <h4 className="text-yellow-500 text-lg mt-4">Mail</h4>
          <p>saprem.ngo@gmail.com</p>
          <div className="mt-4 flex space-x-4">
            <Link
              to={
                "https://www.facebook.com/sharer/sharer.php?u=http%3A%2F%2Fsapremngo.in"
              }
            >
              <FaFacebook className="text-xl" />
            </Link>

            <Link to="https://www.youtube.com/channel/UCtKjoV2rSaO7evrb4gvQZFg">
              <FaYoutube className="text-xl" />
            </Link>
            <Link to={"https://www.instagram.com/saprem_ngo"}>
              <FaInstagram className="text-xl" />
            </Link>
          </div>
        </div>

        {/* Links Sections */}
        <div>
          <h4 className="text-yellow-500 text-lg mb-4">Important Links </h4>
          <ul>
            <li className="flex flex-col">
              <Link to="/donate" className="hover:text-yellow-500">
                Donation
              </Link>
              <Link to="/contact" className="hover:text-yellow-500">
                Contact
              </Link>

              <Link to="/education-right" className="hover:text-yellow-500">
                Right To Education
              </Link>
            </li>
          </ul>
        </div>

        {/* Blog Posts */}
        <div>
          <h4 className="text-yellow-500 text-lg mb-4">Background</h4>
          <ul>
            <li>
              <Link className="hover:text-yellow-500">
                SAPREM is an Non Government Organization, Focus on Health, Education ,  Environment , community empowerment and Livelihood etc.
              </Link>
            </li>
          </ul>
        </div>
      </div>
      <div className="max-w-7xl p-5 mx-auto mt-10 text-center border-t border-gray-700 pt-6">
        <p> Design With ❤️</p>
      </div>
    </footer>
  );
};

export default Footer;
