import React, { useState } from "react";
import p1 from "../../../../assets/projects/past/u.png";
import Logo from "../../../../assets/projects/past/blogo.png";

const Udaan = () => {
  const images = [p1];
  const [selectedImage, setSelectedImage] = useState(null);

  const openModal = (image) => setSelectedImage(image);
  const closeModal = () => setSelectedImage(null);

  return (
    <div className="bg-gray-50 min-h-screen py-10 px-5">
      {/* Header Section */}
      <div className="max-w-6xl mx-auto bg-white shadow-md rounded-lg p-6">
        <div className="flex flex-col lg:flex-row items-center justify-between">
          {/* Logo */}
          <img src={Logo} alt="Company Logo" className="h-16 w-auto" />
          {/* Title */}
          <h1 className="text-2xl lg:text-3xl font-bold text-orange-600 text-center">
            Udaan Shelter, Vasai{" "}
          </h1>
        </div>
      </div>

      {/* Content Section */}
      <div className="max-w-6xl mx-auto bg-white shadow-md rounded-lg mt-8 p-6">
        <p className="text-gray-600 leading-7">
          This is a shelter where women survivors of human trafficking live. The
          house offers them a home where their needs for shelter, food, health,
          education, leisure activities, and legal aid are met. They are also
          supported in acquiring skills to become self-sufficient: they resume
          their education, develop professional skills, learn their rights, and
          prepare themselves to work and successfully reintegrate into society.
        </p>

        <p className="text-gray-600 leading-7 mt-4">
          The women in the home also receive support to heal their mental
          health. The aim is to help them identify their psychological problems,
          offer therapy services, and refer cases of particular complexity to
          other professionals or institutions for more specialised
          interventions. This is combined with art therapy sessions through the
          handmade production of candles or roses.
        </p>

        <p className="text-gray-600 leading-7 mt-4">
          We also seek to improve their living conditions through training and
          educational support in life skills, social skills, etc. through
          training sessions that allow them to identify and work on their own
          abilities.
        </p>

        <h3 className="text-lg lg:text-xl font-semibold text-red-600 mt-6">
          Result:
        </h3>
        <ul className="list-disc list-inside text-gray-600 mt-4 space-y-2">
          <li>
            <span className="font-semibold">Total beneficiaries:</span> 6
          </li>
          <li>
            <span className="font-semibold">Care taker:</span> 1
          </li>
          <li>
            <span className="font-semibold">Project Manager:</span> 1
          </li>
          <li>
            <span className="font-semibold">Trainer:</span> 1
          </li>
        </ul>
      </div>

      {/* Image Gallery */}
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 mt-8">
        {images.map((image, index) => (
          <div
            key={index}
            className="relative group cursor-pointer"
            onClick={() => openModal(image)}
          >
            <img
              src={image}
              alt={`Project ${index + 1}`}
              className="w-full h-full object-cover rounded-lg shadow-md transition-transform duration-300 transform group-hover:scale-105"
            />
          </div>
        ))}
      </div>

      {/* Image Modal */}
      {selectedImage && (
        <div
          className="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center z-50"
          onClick={closeModal}
        >
          <div className="relative max-w-3xl w-full p-4">
            <img
              src={selectedImage}
              alt="Selected"
              className="w-full max-h-[80vh] object-contain rounded-lg"
            />
            <button
              onClick={closeModal}
              className="absolute top-4 right-4 bg-white text-black rounded-full p-2 text-lg font-bold shadow-md hover:shadow-lg"
            >
              ✕
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Udaan;
