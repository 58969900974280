import React, { useState } from "react";
import p1 from "../../../../assets/projects/w1.png";
import p2 from "../../../../assets/projects/w2.png";
import p3 from "../../../../assets/projects/w3.png";
import p4 from "../../../../assets/projects/w4.png";
import p5 from "../../../../assets/projects/w5.png";
const Water = () => {
  const images = [p1, p2, p3, p4, p5];
  const [selectedImage, setSelectedImage] = useState(null);

  const openModal = (image) => setSelectedImage(image);
  const closeModal = () => setSelectedImage(null);
  return (
    <div className="p-5">
      <div className="bg-gray-50 p-6 md:p-12 rounded-lg shadow-md">
        {/* Header Section */}
        <header className="text-center mb-8">
          <h1 className="text-3xl font-bold text-blue-700">
            Water Project - Water Pond Project
          </h1>
          <h2 className="text-xl font-semibold text-gray-600 mt-2">
            Supporting Sustainable Villages and Environmental Growth
          </h2>
        </header>

        {/* Content Section */}
        <section className="text-gray-700 mb-8">
          <p>
            Tigaon is the model village of Sangamner, Maharashtra, where the
            village Grampanchayat requested our assistance in creating a water
            pond to preserve water for their trees. This was the first village
            to seek our help, contributing 50% of the project's cost in the
            previous year. As a result, they now support over 20,000 plants in
            their village, making them one of the first self-sustainable
            villages in Maharashtra. The fruit-bearing plants they have planted
            promise significant income for the village.
          </p>
          <p className="mt-4">
            Chincholi Gurav is another village that planted 4,000-5,000 plants
            last year. However, due to water scarcity, they required a water
            pond to sustain these plants during the summer. The Grampanchayat
            plans to plant an additional 10,000 plants in the coming days. The
            water pond will store 80,00,000 liters of water and preserve nearly
            15,000 plants. The villagers are now motivated to implement various
            environmental and developmental activities to further enhance their
            village's sustainability.
          </p>
        </section>

        {/* Key Points Section */}
        <section className="grid grid-cols-1 md:grid-cols-2 gap-6 text-gray-800 mb-8">
          <div className="bg-white p-4 rounded-lg shadow-md">
            <h3 className="text-lg font-semibold text-blue-700 mb-2">
              Tigaon Water Pond
            </h3>
            <p>Providing water to 22,000 plants in the village.</p>
          </div>
          <div className="bg-white p-4 rounded-lg shadow-md">
            <h3 className="text-lg font-semibold text-blue-700 mb-2">
              Chincholi Gurav Village
            </h3>
            <p>Planned to plant 10,000 more plants in the upcoming days.</p>
          </div>
        </section>

        {/* Village Details Section */}
        <section className="bg-blue-100 p-4 rounded-lg shadow-md text-gray-800">
          <h3 className="text-lg font-semibold text-blue-700 mb-2">
            Project Details:
          </h3>
          <ul className="list-disc pl-5">
            <li>
              <strong>Village Name:</strong> Chincholi Gurav
            </li>
            <li>
              <strong>Project Name:</strong> Water Pond
            </li>
            <li>
              <strong>Number of Beneficiaries:</strong> 5,000
            </li>
          </ul>
        </section>

        {/* Image Section */}
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
          {images.map((image, index) => (
            <div
              key={index}
              className="relative group cursor-pointer"
              onClick={() => openModal(image)}
            >
              <img
                src={image}
                alt={`Project ${index + 1}`}
                className="w-full h-64 object-cover rounded-lg shadow-md transition-transform duration-300 transform group-hover:scale-105"
              />
            </div>
          ))}
        </div>

        {selectedImage && (
          <div
            className="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center z-50"
            onClick={closeModal}
          >
            <div className="relative max-w-3xl w-full p-4">
              <img
                src={selectedImage}
                alt="Selected"
                className="w-full max-h-[80vh] object-contain rounded-lg"
              />
              <button
                onClick={closeModal}
                className="absolute top-4 right-4 bg-white text-black rounded-full p-2 text-lg font-bold shadow-md hover:shadow-lg"
              >
                ✕
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Water;
