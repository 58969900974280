import React, { useState } from "react";
import p1 from "../../../../assets/projects/past/c1.png";
import Logo from "../../../../assets/projects/past/alogo.png";

const CommunityHall = () => {
  const images = [p1];
  const [selectedImage, setSelectedImage] = useState(null);

  const openModal = (image) => setSelectedImage(image);
  const closeModal = () => setSelectedImage(null);

  return (
    <div className="bg-gray-50 min-h-screen py-10 px-5">
      {/* Header Section */}
      <div className="max-w-6xl mx-auto bg-white shadow-md rounded-lg p-6">
        <img src={Logo} alt="Company Logo" className="h-16 w-auto" />
        <div>
          <h1 className="text-2xl lg:text-3xl font-bold text-orange-600 text-center">
          Community Hall construction work, Javkheda
          <br />
          </h1>
        </div>
      </div>

      {/* Content Section */}
      <div className="max-w-6xl mx-auto bg-white shadow-md rounded-lg mt-8 p-6">
        {/* Project Objective */}
        <h2 className="text-xl lg:text-2xl font-semibold text-gray-700 mb-4">
          Project Objective
        </h2>
        <p className="text-gray-600 leading-7">
        <h2 class="font-bold text-xl mb-4">Population of Javkheda Khurd</h2>
  
  <table class="min-w-full table-auto">
    <thead>
      <tr class="bg-gray-100 text-left">
        <th class="px-4 py-2 border-b">Particulars</th>
        <th class="px-4 py-2 border-b">Total</th>
        <th class="px-4 py-2 border-b">Male</th>
        <th class="px-4 py-2 border-b">Female</th>
      </tr>
    </thead>
    <tbody>
      <tr class="bg-white">
        <td class="px-4 py-2 border-b">Total Population</td>
        <td class="px-4 py-2 border-b">975</td>
        <td class="px-4 py-2 border-b">519</td>
        <td class="px-4 py-2 border-b">456</td>
      </tr>
      <tr class="bg-gray-50">
        <td class="px-4 py-2 border-b">Literate Population</td>
        <td class="px-4 py-2 border-b">577</td>
        <td class="px-4 py-2 border-b">361</td>
        <td class="px-4 py-2 border-b">216</td>
      </tr>
      <tr class="bg-white">
        <td class="px-4 py-2 border-b">Illiterate Population</td>
        <td class="px-4 py-2 border-b">398</td>
        <td class="px-4 py-2 border-b">158</td>
        <td class="px-4 py-2 border-b">240</td>
      </tr>
    </tbody>
  </table>
        </p>

        {/* Project Beneficiaries */}
        <h2 className="text-xl lg:text-2xl font-semibold text-gray-700 mt-8">
          Project Beneficiaries
        </h2>
        <p className="text-gray-600 leading-7">
        The community center will directly benefit hundreds of children, teenagers, and adults
in Javkheda village indirectly; the construction of a community center is expected to
positively impact the economy and society in the long run by organizing skill
development programs for child’s women and farmers. Construction of the center and
implementation of the different programs /activities. The community center will be a
structure planned, built, and operated by local authorities
        </p>
        

        <h2 className="text-xl lg:text-2xl font-semibold text-gray-700 mt-8">
        Implementation Strategies applied
        </h2>
        <ul className="list-disc list-inside text-gray-600 mt-4 space-y-2">

          <li>Identifying and focusing on village for need of community hall.</li>
          <li>Demand driven and participatory approach through village key persons and by creating youth
          councils.</li>
          <li>Construction of community hall centre.</li>
          <li>Awareness about use of facilities of community hall.</li>
          <li>Organize workshops on various aspects skill development training program for children and
          women and social culture activities of senior citizens.</li>

        </ul >

    

        {/* Project Outcomes */}
        <h2 className="text-xl lg:text-2xl font-semibold text-gray-700 mt-8">
          Project Outcomes
        </h2>
       
        <ul className="list-disc list-inside text-gray-600 mt-4 space-y-2">
          <li>
          Helpful for farmers of villages for development of knowledge of framing by
          organizing training programs for farmers.
          </li>
          <li>
          Helpful for women of the village to develop skills.
          </li>
          <li>Helpful for the children of the village to take benefits of various training programs.</li>
          <li>Village youth also benefit from various social and cultural activities.</li>
          <li>
          Social culture activities of senior citizens.
          </li>
          <li>
          Students and youths can use as study place.          </li>



          
        </ul>

        {/* Conclusion */}
        
      </div>

      {/* Image Gallery */}
      <br />
      <div className="">
        {images.map((image, index) => (
          <div
            key={index}
            className="relative group cursor-pointer"
            onClick={() => openModal(image)}
          >
            <img
              src={image}
              alt={`Project ${index + 1}`}
              className="w-full  object-cover rounded-lg shadow-md transition-transform duration-300 transform group-hover:scale-105"
            />
          </div>
        ))}
      </div>

      {/* Modal for Image */}
      {selectedImage && (
        <div
          className="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center z-50"
          onClick={closeModal}
        >
          <div className="relative max-w-3xl w-full p-4">
            <img
              src={selectedImage}
              alt="Selected"
              className="w-full max-h-[80vh] object-contain rounded-lg"
            />
            <button
              onClick={closeModal}
              className="absolute top-4 right-4 bg-white text-black rounded-full p-2 text-lg font-bold shadow-md hover:shadow-lg"
            >
              ✕
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default CommunityHall;
