import React, { useState } from "react";
import p1 from "../../../../assets/projects/p1.png";
import p2 from "../../../../assets/projects/p2.png";
import p3 from "../../../../assets/projects/p3.png";
import p4 from "../../../../assets/projects/p4.png";
import p5 from "../../../../assets/projects/p5.png";
import p6 from "../../../../assets/projects/p6.png";
import p7 from "../../../../assets/projects/p7.png";
import p8 from "../../../../assets/projects/p8.png";
import p9 from "../../../../assets/projects/p9.png";
import p10 from "../../../../assets/projects/p10.png";

const Jungle = () => {
  const images = [p1, p2, p3, p4, p5, p6, p7, p8, p9, p10];
  const [selectedImage, setSelectedImage] = useState(null);

  const openModal = (image) => setSelectedImage(image);
  const closeModal = () => setSelectedImage(null);
  return (
    <div className="p-5">
      <div className="bg-gray-50 p-6 md:p-12">
        <header className="text-center mb-8">
          <h1 className="text-3xl font-bold text-green-700">
            DORF KETAL JUNGLE CUP
          </h1>
          <h2 className="text-xl font-semibold text-gray-600 mt-2">
            Dorf Ketal Jungle Cup 2022-24
          </h2>
        </header>

        <section className="mb-8">
          <p className="text-gray-700 leading-7">
            Mr. Santosh Jagdhane, GM, Dorf Ketal Chemical India Pvt. Ltd., has
            worked on Miyawaki Rapid Forest since the last few years. He has
            implemented the concept with various organizations in India,
            including Madhya Pradesh, Kerala, and Maharashtra, like SAPREM’s at
            “Bring A Smile School,” Birwadi, Shahapur in 2019. Dr. Nagesh Tekale
            of Navdrushti, SAVALI NGO, helped implement this innovative project
            for the first time, and the classic method of planting saplings was
            understood. These saplings grow very fast because of competition
            among 41 types of species in a specific area of 1000 sq. ft., where
            300 plants (Canopy trees 15%, Large Trees 60%, Medium Trees 15%, and
            Small Trees 10%) are planted with a distance of 1 sq. ft. between
            each.
          </p>

          <p className="text-gray-700 leading-7 mt-4">
            After the successful plantation and growth of the trees in Miyawaki
            after two years, Mr. Santosh Jagdhane discussed the concept with
            SAPREM to expand the project activities in rural areas. Thus, the
            Dorf Ketal Jungle Cup 2023 project was initiated in Sangamner Block
            with financial support from Dorf Ketal’s CSR and technical partner
            NAVDRUSHTI. Our partners personally visited the project area and got
            permission from the Block Development Officer (BDO) Panchayat Samiti
            Sangamner.
          </p>

          <p className="text-gray-700 leading-7 mt-4">
            A project coordinator visited almost 110 villages out of 144 in the
            block. Most of the Grampanchayats submitted their request to SAPREM,
            and 50 Grampanchayats were selected to implement the project
            activities. Meetings with stakeholders were held, and a one-day
            Miyawaki training was organized for these Grampanchayats and
            stakeholders at Sangamner. A demonstration site was created at the
            Panchayat Samiti office, and live demonstrations were given to all
            participants by the trainer Dr. Nagesh Tekale and Mr. Santosh
            Jagdhane, GM CSR, Dorf Ketal. A training module was also distributed
            to all participants, providing the necessary information for making
            Dorf Ketal Jungles in their villages.
          </p>
        </section>

        {/* Jungle Cup Flow Chart */}
        <section className="bg-white p-6 shadow-md rounded-lg">
          <h3 className="text-2xl font-bold text-green-600 text-center mb-6">
            Jungle Cup Flow Chart
          </h3>
          <div className="flex flex-col md:flex-row items-start justify-between space-y-6 md:space-y-0 md:space-x-8">
            {/* Left Content */}
            <div className="flex flex-col space-y-6">
              <div className="flex items-center">
                <div className="w-2 h-2 bg-red-500 rounded-full mr-4"></div>
                <p className="text-gray-700 font-medium">
                  NGO’s Planning & Training to Staff for Miyawaki Rapid Forest
                  Development
                </p>
              </div>
              <div className="flex items-center">
                <div className="w-2 h-2 bg-blue-500 rounded-full mr-4"></div>
                <p className="text-gray-700 font-medium">
                  NGOs collect the Application Forms from the Grampanchayats for
                  their entry to participate in the ‘Jungle Cup’ Competition
                </p>
              </div>
              <div className="flex items-center">
                <div className="w-2 h-2 bg-green-500 rounded-full mr-4"></div>
                <p className="text-gray-700 font-medium">
                  Inauguration of Miyawaki Rapid Forest at Village Level
                </p>
              </div>
              <div className="flex items-center">
                <div className="w-2 h-2 bg-yellow-500 rounded-full mr-4"></div>
                <p className="text-gray-700 font-medium">
                  Fencing and watering is done by the Grampanchayats as per the
                  rules and regulations of the ‘Jungle Cup’
                </p>
              </div>
            </div>

            {/* Right Content */}
            <div className="flex flex-col space-y-6">
              <div className="flex items-center">
                <div className="w-2 h-2 bg-purple-500 rounded-full mr-4"></div>
                <p className="text-gray-700 font-medium">
                  NGO Partners & Funding Agency jointly visit the project area
                </p>
              </div>
              <div className="flex items-center">
                <div className="w-2 h-2 bg-indigo-500 rounded-full mr-4"></div>
                <p className="text-gray-700 font-medium">
                  Training on Miyawaki Rapid Forest to the Grampanchayats and
                  Government Officials like BDO, Village Development Officers,
                  Sarpanch, etc.
                </p>
              </div>
              <div className="flex items-center">
                <div className="w-2 h-2 bg-orange-500 rounded-full mr-4"></div>
                <p className="text-gray-700 font-medium">
                  Plantation by the people, students, officials through
                  “Shramdaan”
                </p>
              </div>
            </div>
          </div>
        </section>
      </div>

      {/* Modal */}
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
        {images.map((image, index) => (
          <div
            key={index}
            className="relative group cursor-pointer"
            onClick={() => openModal(image)}
          >
            <img
              src={image}
              alt={`Project ${index + 1}`}
              className="w-full h-64 object-cover rounded-lg shadow-md transition-transform duration-300 transform group-hover:scale-105"
            />
          </div>
        ))}
      </div>

      {selectedImage && (
        <div
          className="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center z-50"
          onClick={closeModal}
        >
          <div className="relative max-w-3xl w-full p-4">
            <img
              src={selectedImage}
              alt="Selected"
              className="w-full max-h-[80vh] object-contain rounded-lg"
            />
            <button
              onClick={closeModal}
              className="absolute top-4 right-4 bg-white text-black rounded-full p-2 text-lg font-bold shadow-md hover:shadow-lg"
            >
              ✕
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Jungle;
