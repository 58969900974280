import React from "react";
import ha1 from "../../../assets/home/ha1.jpg";
import ha2 from "../../../assets/home/ha2.jpg";
import ha3 from "../../../assets/home/ha3.jpg";
const KnowAboutUs = () => {
  const infoCards = [
    {
      title: "Our Mission",
      description:
        "“Identify the impact of socio-economic issues and strive for social, educational and economic well-being of the human being in the society by ensuring community participation through participatory methods”",
      image:
        "https://give.do/blog/wp-content/uploads/2023/11/The-mission-of-fundraising-sites-in-India-bringing-passionate-individuals-and-NGOs-together-for-a-better-world-Give-blog.jpg",
    },
    {
      title: "Our Vision",
      description:
        "To engage ourselves for the community empowerment through social work interventions by creating awareness, care, support system and provide the basic needs of the underprivileged society by using individuals, groups, Government, national and international organizations and likeminded agencies.",
      image:
        "https://www.aurumliving.com/blog/wp-content/uploads/2021/07/Old-Age-Home-870x600.jpg",
    },
    {
      title: "Goals/Objectives",
      description:
        "To undertake formal and informal educational projects for educational development    of the society.To undertake various projects for the development of socially, economically and educationally weak sections",
      image: ha3,
    },
  ];

  return (
    <div className="bg-gray-100 p-8">
      <h2 className="text-4xl font-bold text-center text-gray-800 mb-10">
        Know About Us
      </h2>

      <div className="grid grid-cols-1 md:grid-cols-3 gap-8 max-w-7xl mx-auto p-5">
        {infoCards.map((card, index) => (
          <div
            key={index}
            className="group bg-white shadow-lg rounded-lg overflow-hidden transition-transform transform hover:scale-105"
          >
            {/* Image */}
            <div className="relative h-48">
              <img
                src={card.image}
                alt={card.title}
                className="w-full h-full object-cover transition-transform transform group-hover:scale-110"
              />
            </div>

            {/* Content */}
            <div className="p-6">
              <h3 className="text-2xl font-semibold text-gray-800 mb-4 group-hover:text-blue-500 transition-colors">
                {card.title}
              </h3>
              <p className="text-gray-600">{card.description}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default KnowAboutUs;
