import React from "react";
import p1 from "../../../assets/p1.png";
import p2 from "../../../assets/p2.png";
import p3 from "../../../assets/p3.png";
import p4 from "../../../assets/p4.png";
import p5 from "../../../assets/dorf.jpeg"

const Client = () => {
  return (
    <div>
      <br />
      <div className="my-10">
        <div className=" flex flex-col  w-full items-center">
          <h3 className=" text-2xl lg:text-4xl font-semibold  text-black">
            Our Partners
          </h3>
          <br />
          <div className="flex items-center w-[75px]">
            <div className="h-0.5 bg-[#cee21a]"></div>
            <div className="h-1 w-1 bg-[#cee21a] rounded-full mx-1"></div>
            <div className="h-1 w-1 bg-[#cee21a] rounded-full mx-1"></div>
            <div className="h-1 w-1 bg-[#cee21a] rounded-full mx-1"></div>
            <div
              className="h-[4px] rounded-full w-[10px] flex-grow"
              style={{ backgroundColor: "#cee21a" }}
            ></div>
          </div>
                    
        </div>
        <p className="text-center text-xl px-5">
          SAPREM valuable donors , CSR , foreign funding , Grants from
          funding agencies.
        </p>
        <div className="max-w-7xl mx-auto my-10 px-5">
  <div className="grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-5 gap-4 justify-center">
    <img src={p1} alt="not found" />
    <img src={p2} alt="not found" />
    <img src={p3} alt="not found" />
    <img src={p4} alt="not found" />
    <img src={p5} alt="not found" />
  </div>
</div>

      </div>
      <br />
    </div>
  );
};

export default Client;
