import React, { useState } from "react";
import Logo from "../../../../assets/projects/past/alogo.png";
import health1 from "../../../../assets/projects/past/health1.png";
import health2 from "../../../../assets/projects/past/health2.png";
import health3 from "../../../../assets/projects/past/health3.png";
const Health = () => {
  const images = [health1,health2,health3];
  const [selectedImage, setSelectedImage] = useState(null);

  const openModal = (image) => setSelectedImage(image);
  const closeModal = () => setSelectedImage(null);
  return (
    <div className="bg-gray-50 min-h-screen py-10 px-5">
      {/* Header Section */}
      <div className="max-w-6xl mx-auto bg-white shadow-md rounded-lg p-6">
        <div className="flex flex-col lg:flex-row items-center justify-between">
          {/* Logo */}
          <img src={Logo} alt="Company Logo" className="h-16 w-auto" />
          {/* Title */}
          <h1 className="text-2xl lg:text-3xl font-bold text-orange-600 text-center">
          Health project            
          </h1>
        </div>
      </div>

      {/* Content Section */}
      <div className="max-w-6xl mx-auto bg-white shadow-md rounded-lg mt-8 p-6">
        {/* Introduction */}
        <h2 className="text-xl lg:text-2xl font-semibold text-gray-700 mb-4">
          About the Project
        </h2>
        <p className="text-gray-600 leading-7">
        SAPREM has been working since 2008 to bring health care closer to the
inhabitants of the most disadvantaged communities, providing check-ups and specialized
treatment free of charge. People with visual, hearing or dental problems or with infectious
diseases such as tuberculosis, malaria or dengue have been able to receive the necessary care.
Since its inception, the community health programme has provided almost 20,000 check-ups,
distributed more than 2,500 spectacles and carried out almost 500 dental treatments and covid-19
vaccination till 2022 and, There is a great lack of information about diseases and how to prevent
them. Concepts such as hygiene, health care, or going to the doctor when necessary, are often not
part of their daily habits. For this reason, Mumbai Smiles brings these concepts to the
communities through presentations and campaigns.
        </p>
        <p className="text-gray-600 leading-7 mt-4">
        <span>Community awareness:</span> We organize activities with our communities in Mumbai to raise
awareness about their reality and foster change. We highlight relevant International Days, such as
International AIDS Day, Childhood Day, Women’s Day, and Human Rights Day, to confront
different social problems.
        </p>

        {/* Project Outcomes */}
        <h2 className="text-xl lg:text-2xl font-semibold text-gray-700 mt-8">
        Eye checkup camps
                </h2>
        <p className="text-gray-600 leading-7 mt-4">
        The Community Health team regularly organize a free Eye Check up
camp in collaboration with the Preschool Education team. With the aim of early detection of eye
ailments in children as well as spread awareness about eye health among the parents of the
students, the free eye check up camp has been organized in 12 preschool centers With the support
of Opthamatry Dr. Manish and Dr. Ashwini, in collaboration with Aditya Jyot Hospital, children
diagnose with different eye issues, including refractive errors. Those needing deeper evaluation,
further tests or serious treatment, have been referred to the hospital for further procedure. Others,
who have been detected with minor vision issues that can be corrected with the use of spectacles,
will receive the same in some days’ time. In the meantime, the parents of the students were given
a general counseling on how to maintain their and their children’s eye health by eating healthy
(such as green leafy vegetables) and ensuring eye hygiene by washing eyes in the morning and
after back home from outside and other such quick preventive measures.
        </p>

        <h3 className="text-lg lg:text-xl font-semibold text-orange-600 mt-6">
        Total beneficiaries for eye checkup : 258 preschool children        </h3>
        
      </div>
      <br />
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
        {images.map((image, index) => (
          <div
            key={index}
            className="relative group cursor-pointer"
            onClick={() => openModal(image)}
          >
            <img
              src={image}
              alt={`Project ${index + 1}`}
              className="w-full h-64 object-cover rounded-lg shadow-md transition-transform duration-300 transform group-hover:scale-105"
            />
          </div>
        ))}
      </div>

      {selectedImage && (
        <div
          className="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center z-50"
          onClick={closeModal}
        >
          <div className="relative max-w-3xl w-full p-4">
            <img
              src={selectedImage}
              alt="Selected"
              className="w-full max-h-[80vh] object-contain rounded-lg"
            />
            <button
              onClick={closeModal}
              className="absolute top-4 right-4 bg-white text-black rounded-full p-2 text-lg font-bold shadow-md hover:shadow-lg"
            >
              ✕
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Health;
