import React, { useState } from "react";
import Logo from "../../../../assets/projects/past/blogo.png";
import p1 from "../../../../assets/projects/past/b1.png";
import p2 from "../../../../assets/projects/past/b2.png";
import p3 from "../../../../assets/projects/past/b3.png";
import p4 from "../../../../assets/projects/past/b4.png";

const Education = () => {
  const images = [p1, p2, p3, p4];
  const [selectedImage, setSelectedImage] = useState(null);

  const openModal = (image) => setSelectedImage(image);
  const closeModal = () => setSelectedImage(null);
  return (
    <div className="bg-gray-50 min-h-screen py-10 px-5">
      {/* Header Section */}
      <div className="max-w-6xl mx-auto bg-white shadow-md rounded-lg p-6">
        <div className="flex flex-col lg:flex-row items-center justify-between">
          {/* Logo */}
          <img src={Logo} alt="Company Logo" className="h-16 w-auto" />
          {/* Title */}
          <h1 className="text-2xl lg:text-3xl font-bold text-orange-600 text-center">
            ACCESS TO EDUCATION
          </h1>
        </div>
      </div>

      {/* Content Section */}
      <div className="max-w-6xl mx-auto bg-white shadow-md rounded-lg mt-8 p-6">
        {/* Introduction */}
        <h2 className="text-xl lg:text-2xl font-semibold text-gray-700 mb-4">
          About the Project
        </h2>
        <p className="text-gray-600 leading-7">
          SAPREM in collaboration with mumbai smile working for unprivileged
          childrens We all know that education is the most powerful weapon which
          you can use to change the world. We, at Mumbai Smiles try to inculcate
          this thought in communities where we conduct our projects. We work in
          a team to create different educational opportunities for all our
          beneficiaries.
        </p>
        <p className="text-gray-600 leading-7 mt-4">
          Smiling Bus is one of the projects aimed at providing access to
          education for all those children who are deprived of this basic right
          and necessity. Smiling Bus is for children from families where there
          is a lack of awareness of the importance of education. At the same
          time this is for those who want to learn but need motivation to
          overpower their weaknesses and adverse situations.
        </p>

        {/* Project Outcomes */}
        <h2 className="text-xl lg:text-2xl font-semibold text-gray-700 mt-8">
          Project Outcomes
        </h2>
        <p className="text-gray-600 leading-7 mt-4">
          Our Smiling Bus team organizes different activities under the LSE
          (Life skill empowerment) project for these kids and encourages them to
          attend schools which are located near their communities. However, due
          to the unhygienic appearance, these kids are not acceptable in their
          schools. Children who have better living conditions do not want to
          study with these kids and hence these kids avoid going to school
          feeling humiliated. Also, due to the lack of education and guidance
          they are addicted to drugs at very early age
        </p>

        {/* Conclusion */}
        <p className="text-gray-600 leading-7 mt-6">
          Considering their situation, Mumbai Smiles have decided to find a very
          interesting solution. We are planning to create a library in the
          Smiling Bus for these kids and provide educational guidance according
          to their age groups. This would lay the foundation for those kids who
          have a desire to study and want to appear for various competitive
          exams by pursuing education in flexible manner.
        </p>
      </div>
      <br />
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
        {images.map((image, index) => (
          <div
            key={index}
            className="relative group cursor-pointer"
            onClick={() => openModal(image)}
          >
            <img
              src={image}
              alt={`Project ${index + 1}`}
              className="w-full h-full object-cover rounded-lg shadow-md transition-transform duration-300 transform group-hover:scale-105"
            />
          </div>
        ))}
      </div>

      {selectedImage && (
        <div
          className="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center z-50"
          onClick={closeModal}
        >
          <div className="relative max-w-3xl w-full p-4">
            <img
              src={selectedImage}
              alt="Selected"
              className="w-full max-h-[80vh] object-contain rounded-lg"
            />
            <button
              onClick={closeModal}
              className="absolute top-4 right-4 bg-white text-black rounded-full p-2 text-lg font-bold shadow-md hover:shadow-lg"
            >
              ✕
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Education;
