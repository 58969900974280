import React from "react";
import { Link } from "react-router-dom";
import volunteer from '../assets/volunteer.jpg'

const EventPage = () => {
  const events = [
    {
      id: 1,
      title: "Tree Plantation Drive",
      //   date: "December 25, 2024",
      location: "Mumbai, India",
      description:
        "Join us to make the city greener. Let's plant trees and spread awareness about the environment.",
      image:
        "https://thumbs.dreamstime.com/b/people-hand-helping-plant-tree-working-together-farm-conc-concept-save-world-94990713.jpg", // Replace with actual image URL
    },
    {
      id: 2,
      title: "Health Checkup Camp",
      //   date: "January 5, 2025",
      location: "Delhi, India",
      description:
        "Free health checkup for underprivileged communities. Be a part of this noble cause.",
      image:
        "https://productimages.withfloats.com/tile/6593f59da126c474a67a8f08.jpg", // Replace with actual image URL
    },
    {
      id: 3,
      title: "Education Awareness Program",
      //   date: "February 10, 2025",
      location: "Pune, India",
      description:
        "Support education for all. Participate in spreading awareness about the importance of education.",
      image:
        "https://media.istockphoto.com/id/535555239/photo/happy-indian-school-children.jpg?s=612x612&w=0&k=20&c=fcpTUHiHJuaeRS-xHJy4oOflwKpBooiPecyewzohvhk=", // Replace with actual image URL
    },

    {
      id: 4,
      title: "Volunteer Drive",
      //   date: "February 10, 2025",
      location: "Pune, India",
      description:
        "Call for Volunteers! India's 1st Tree plantation competition in Maharashtra",
      image:volunteer
    },
  ];

  return (
    <div className="bg-gray-50 min-h-screen">
      {/* Hero Section */}
      <section className="bg-gradient-to-r from-orange-500 to-pink-500 text-white py-20">
        <div className="max-w-7xl mx-auto text-center px-6">
          <h1 className="text-4xl font-bold mb-4">Upcoming Events</h1>
          <p className="text-lg mb-6">
            Be a part of our initiatives to make the world a better place. Join
            us and make a difference!
          </p>
          <Link
            to={"/event"}
            className="bg-white text-orange-500 px-6 py-3 rounded-full shadow-lg font-semibold hover:bg-gray-100 transition duration-300"
          >
            Learn More
          </Link>
        </div>
      </section>

      {/* Events Section */}
      <section className="py-16">
        <div className="max-w-7xl mx-auto px-6">
          <h2 className="text-3xl font-bold text-gray-800 text-center mb-12">
            Our Events
          </h2>
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">
            {events.map((event) => (
              <div
                key={event.id}
                className="bg-white rounded-lg shadow-md overflow-hidden hover:shadow-lg transition-shadow duration-300"
              >
                <img
                  src={event.image}
                  alt={event.title}
                  className="w-full h-48 object-cover"
                />
                <div className="p-6">
                  <h3 className="text-xl font-bold text-gray-800 mb-2">
                    {event.title}
                  </h3>

                  <p className="text-gray-700 mb-4">{event.description}</p>
                  {/* <button className="bg-orange-500 text-white px-4 py-2 rounded-full text-sm font-semibold hover:bg-orange-600 transition duration-300">
                    Join Now
                  </button> */}
                </div>


                



  


              </div>
            ))}


            

            
          </div>




              



        </div>
      </section>

      {/* Call-to-Action Section */}
      <section className="bg-orange-100 py-12">
        <div className="max-w-7xl mx-auto text-center px-6">
          <h2 className="text-2xl font-bold text-gray-800 mb-4">
            Want to Support Our Mission?
          </h2>
          <p className="text-gray-600 mb-6">
            Your contribution can help us organize more events and support
            communities in need.
          </p>
          <Link
            to="/donate"
            className="bg-orange-500 text-white px-6 py-3 rounded-full shadow-lg font-semibold hover:bg-orange-600 transition duration-300"
          >
            Donate Now
          </Link>
        </div>
      </section>
    </div>
  );
};

export default EventPage;
