import React from "react";
import { FaArrowRight } from "react-icons/fa";

const EducationRight = () => {
  return (
    <div className="max-w-7xl mx-auto p-5">
      <div className="container mx-auto p-5">
        <header className="text-center mb-10">
          <h1 className="text-3xl font-bold text-blue-600 mb-4">
            The Constitution (Eighty-sixth Amendment) Act, 2002
          </h1>
          <p className="text-lg text-gray-700">
            Inserted Article 21-A in the Constitution of India to provide free
            and compulsory education for all children in the age group of six to
            fourteen years as a Fundamental Right in such a manner as the State
            may, by law, determine.
          </p>
        </header>

        <section className="space-y-8">
          <p className="text-lg text-gray-700">
            The Right of Children to Free and Compulsory Education (RTE) Act,
            2009, which represents the consequential legislation envisaged under
            Article 21-A, means that every child has a right to full-time
            elementary education of satisfactory and equitable quality in a
            formal school which satisfies certain essential norms and standards.
          </p>

          <p className="text-lg text-gray-700">
            Article 21-A and the RTE Act came into effect on 1 April 2010. The
            title of the RTE Act incorporates the words ‘free and compulsory’.
            ‘Free education’ means that no child, other than a child who has
            been admitted by his or her parents to a school which is not
            supported by the appropriate Government, shall be liable to pay any
            kind of fee or charges or expenses which may prevent him or her from
            pursuing and completing elementary education. ‘Compulsory education’
            casts an obligation on the appropriate Government and local
            authorities to provide and ensure admission, attendance, and
            completion of elementary education by all children in the 6-14 age
            group.
          </p>

          <p className="text-lg text-gray-700">
            With this, India has moved forward to a rights-based framework that
            casts a legal obligation on the Central and State Governments to
            implement this fundamental child right as enshrined in Article 21-A
            of the Constitution, in accordance with the provisions of the RTE
            Act.
          </p>

          <div className="bg-gray-100 p-5 rounded-lg">
            <h2 className="text-2xl font-semibold text-blue-600 mb-4">
              Key Provisions of the RTE Act
            </h2>
            <ul className="list-disc pl-6 space-y-3 text-lg text-gray-700">
              <li>
                The right of children to free and compulsory education till
                completion of elementary education in a neighborhood school.
              </li>
              <li>
                Clarifies that ‘compulsory education’ means obligation of the
                appropriate government to provide free elementary education and
                ensure compulsory admission, attendance, and completion of
                elementary education to every child in the six to fourteen age
                group. ‘Free’ means that no child shall be liable to pay any
                kind of fee or charges or expenses which may prevent him or her
                from pursuing and completing elementary education.
              </li>
              <li>
                It makes provisions for a non-admitted child to be admitted to
                an age-appropriate class.
              </li>
              <li>
                Specifies the duties and responsibilities of appropriate
                Governments, local authority, and parents in providing free and
                compulsory education, and sharing of financial and other
                responsibilities between the Central and State Governments.
              </li>
              <li>
                Lays down the norms and standards relating inter alia to Pupil
                Teacher Ratios (PTRs), buildings and infrastructure,
                school-working days, and teacher-working hours.
              </li>
              <li>
                Provides for rational deployment of teachers by ensuring that
                the specified pupil-teacher ratio is maintained for each school,
                rather than just as an average for the State or District or
                Block, thus ensuring that there is no urban-rural imbalance in
                teacher postings.
              </li>
              <li>
                Prohibits the deployment of teachers for non-educational work,
                other than decennial census, elections to local authorities,
                state legislatures and parliament, and disaster relief.
              </li>
              <li>
                Provides for the appointment of appropriately trained teachers,
                i.e. teachers with the requisite entry and academic
                qualifications.
              </li>
              <li>
                Prohibits:
                <ul className="list-disc pl-6">
                  <li>Physical punishment and mental harassment;</li>
                  <li>Screening procedures for admission of children;</li>
                  <li>Capitation fee;</li>
                  <li>Private tuition by teachers;</li>
                  <li>Running of schools without recognition.</li>
                </ul>
              </li>
              <li>
                Provides for development of curriculum in consonance with the
                values enshrined in the Constitution, which would ensure the
                all-round development of the child, building on the child’s
                knowledge, potentiality, and talent, making the child free of
                fear, trauma, and anxiety through a system of child-friendly and
                child-centred learning.
              </li>
            </ul>
          </div>
        </section>

        <section className="mt-10">
          <h2 className="text-2xl font-semibold text-blue-600 mb-4">
            Other Links
          </h2>
          <ul className="space-y-3 text-lg text-gray-700">
            <li className="flex items-center">
              <FaArrowRight className="mr-2 text-blue-600" />{" "}
              <a href="#balwadi" className="hover:underline">
                Balwadi Project
              </a>
            </li>
            <li className="flex items-center">
              <FaArrowRight className="mr-2 text-blue-600" />{" "}
              <a href="#mentally-challenged" className="hover:underline">
                School For Mentally Challenged Children
              </a>
            </li>
            <li className="flex items-center">
              <FaArrowRight className="mr-2 text-blue-600" />{" "}
              <a href="#rural-children" className="hover:underline">
                School For Rural Children
              </a>
            </li>
          </ul>
        </section>
      </div>
    </div>
  );
};

export default EducationRight;
