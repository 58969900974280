import React, { useState } from "react";
import p1 from "../../../../assets/projects/m1.png";
import p2 from "../../../../assets/projects/m2.png";
import p3 from "../../../../assets/projects/m3.png";
import p4 from "../../../../assets/projects/m4.png";
import p5 from "../../../../assets/projects/m5.png";
import p6 from "../../../../assets/projects/m6.png";
import p7 from "../../../../assets/projects/m7.png";
import p8 from "../../../../assets/projects/m8.png";
import p9 from "../../../../assets/projects/m9.png";

const Mango = () => {
  const images = [p1, p2, p3, p4, p5, p6, p7, p8, p9];
  const [selectedImage, setSelectedImage] = useState(null);

  const openModal = (image) => setSelectedImage(image);
  const closeModal = () => setSelectedImage(null);
  return (
    <div className="p-5">
      <div className="bg-gray-50 p-6 md:p-12">
        <header className="text-center mb-8">
          <h1 className="text-3xl font-bold text-green-700">
            DORF KETAL MANGO CUP
          </h1>
          <h2 className="text-xl font-semibold text-gray-600 mt-2">
            Dorf Ketal Mango Cup 2023-24
          </h2>
        </header>
        <section className="text-gray-700 mb-8">
        <p><span class="text-blue-500">🌾</span> NUMBER OF VILLAGES VISITED - 300</p>
  <p><span class="text-green-500">📍</span> NUMBER OF TALUKA VISITED - 5</p>
  <p><span class="text-orange-500">✅</span> NUMBER OF VILLAGES FINALISED FOR DKMC PROJECT - 29</p>
  <p><span class="text-purple-500">👩‍🌾</span> NUMBER OF BENEFICIARY IN 3 TALUKA</p>
  <p><span class="text-red-500">🌾</span> NUMBER OF BENEFICIARY - FARMERS - 14,300</p>
  <p><span class="text-yellow-500">🏠</span> NUMBER OF GRAMPANCHAYAT BENEFICIARY - 52,000</p>


        </section>
        <section className="text-gray-700 mb-8">
          <p>
            Following are a few glimpses of villages where Kesar Mango plants
            are grown up on the open space of each Grampanchayat in various
            villages of Ahmednagar and Nashik Districts:
          </p>
        </section>
        <section className="text-gray-700 mb-8">
          <p>
            SAPREM had organized various training programs online as well as
            offline for the farmers and Grampanchayats’ Members to train them
            about plantation and effective production of Kesar Mangoes.
          </p>
        </section>
        <section className="text-gray-700 mb-8">
          <p>
            A CSR Head of Dorf Ketal, Mr. Santosh Jagdhane, visited many
            villages during the year at different Mango Orchards like Bhokani,
            Shaha, Virgaon, Tigaon, etc.
          </p>
        </section>
      </div>

      {/* Modal */}
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
        {images.map((image, index) => (
          <div
            key={index}
            className="relative group cursor-pointer"
            onClick={() => openModal(image)}
          >
            <img
              src={image}
              alt={`Project ${index + 1}`}
              className="w-full h-64 object-cover rounded-lg shadow-md transition-transform duration-300 transform group-hover:scale-105"
            />
          </div>
        ))}
      </div>

      {selectedImage && (
        <div
          className="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center z-50"
          onClick={closeModal}
        >
          <div className="relative max-w-3xl w-full p-4">
            <img
              src={selectedImage}
              alt="Selected"
              className="w-full max-h-[80vh] object-contain rounded-lg"
            />
            <button
              onClick={closeModal}
              className="absolute top-4 right-4 bg-white text-black rounded-full p-2 text-lg font-bold shadow-md hover:shadow-lg"
            >
              ✕
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Mango;
