import React, { useState } from "react";
import p1 from "../../../../assets/projects/past/r1.png";
import p2 from "../../../../assets/projects/past/r2.png";
import p3 from "../../../../assets/projects/past/r3.png";
import p4 from "../../../../assets/projects/past/r4.png";
import p5 from "../../../../assets/projects/past/r5.png";
import p6 from "../../../../assets/projects/past/r6.png";
import Logo from "../../../../assets/projects/past/blogo.png";

const Rescue = () => {
  const images = [p1, p2, p3, p4, p5, p6];
  const [selectedImage, setSelectedImage] = useState(null);

  const openModal = (image) => setSelectedImage(image);
  const closeModal = () => setSelectedImage(null);
  return (
    <div className="bg-gray-50 min-h-screen py-10 px-5">
      {/* Header Section */}
      <div className="max-w-6xl mx-auto bg-white shadow-md rounded-lg p-6">
        <div className="flex flex-col lg:flex-row items-center justify-between">
          {/* Logo */}
          <img src={Logo} alt="Company Logo" className="h-16 w-auto" />
          {/* Title */}
          <h1 className="text-2xl lg:text-3xl font-bold text-orange-600 text-center">
            RESCUE PROJECT (ANTI HUMAN TRAFFICKING)
          </h1>
        </div>
      </div>

      {/* Content Section */}
      <div className="max-w-6xl mx-auto bg-white shadow-md rounded-lg mt-8 p-6">
        {/* About the Project */}
        <h2 className="text-xl lg:text-2xl font-semibold text-gray-700 mb-4">
          About the Project
        </h2>
        <p className="text-gray-600 leading-7">
          It's commendable that SAPREM and Mumbai Smiles have been collaborating
          since 2010 to address poverty and promote human rights in Mumbai.
          Their joint efforts to raise awareness about human trafficking and
          prevent it are crucial in combating this heinous crime. Despite these
          efforts, it's disheartening to acknowledge that many women and girls
          still become victims of human trafficking networks every year.
        </p>
        <p className="text-gray-600 leading-7 mt-4">
          To effectively combat human trafficking, continued collaboration
          between organizations like SAPREM and Mumbai Smiles is essential. This
          includes not only raising awareness but also providing support
          services for survivors, advocating for stronger legal frameworks, and
          addressing the root causes of vulnerability that make individuals
          susceptible to trafficking.
        </p>
        <p className="text-gray-600 leading-7 mt-4">
          Every person deserves to live a life of dignity and freedom, and it's
          inspiring to see organizations working tirelessly towards this goal,
          leading them to a future free of sexual exploitation. The aim of this
          project is to rescue them and be able to repatriate them to their
          places of origin if they wish so.
        </p>

        {/* Key Intervention Details */}
        <h2 className="text-xl lg:text-2xl font-semibold text-gray-700 mt-8">
          Intervention and Rescue Details
        </h2>
        <p className="text-gray-600 leading-7 mt-4">
          This intervention requires perfect coordination with local
          authorities, police, and specific anti-trafficking units, and their
          full cooperation is required. The first step of any rescue operation
          involves detailed information gathering to identify girls and women
          who are being forced into prostitution. Once it is certain that they
          are suffering from a trafficking crime, the support of the Indian
          police and their Anti Human Trafficking Units is requested to launch
          the actual rescue operation.
        </p>
        <p className="text-gray-600 leading-7 mt-4">
          Once released, they are taken to one of the shelters run by private
          organisations or the authorities. There, these survivors are offered a
          safe space where they can recover from the traumatic situation they
          have been through. At the same time, the legal procedure is set in
          motion to denounce their captors and obtain the women’s exoneration
          since, on paper, they have suffered a violation of their fundamental
          rights, but they have also committed various crimes, one of the most
          common of which is illegal immigration.
        </p>

        {/* Rescue Details */}
        <h3 className="text-lg lg:text-xl font-semibold text-red-600 mt-6">
          Rescue Details (Since 2020 to 31st March 2024):
        </h3>
        <ul className="list-disc list-inside text-gray-600 mt-4 space-y-2">
          <li>
            <span className="font-semibold">
              Total number of rescued victims:
            </span>{" "}
            59
          </li>
          <li>
            <span className="font-semibold">Minor victims:</span> 20
          </li>
          <li>
            <span className="font-semibold">Major victims:</span> 39
          </li>
          <li>
            <span className="font-semibold">Total traffickers arrested:</span>{" "}
            23
          </li>
        </ul>
      </div>

      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
        {images.map((image, index) => (
          <div
            key={index}
            className="relative group cursor-pointer"
            onClick={() => openModal(image)}
          >
            <img
              src={image}
              alt={`Project ${index + 1}`}
              className="w-full h-full object-cover rounded-lg shadow-md transition-transform duration-300 transform group-hover:scale-105"
            />
          </div>
        ))}
      </div>

      {selectedImage && (
        <div
          className="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center z-50"
          onClick={closeModal}
        >
          <div className="relative max-w-3xl w-full p-4">
            <img
              src={selectedImage}
              alt="Selected"
              className="w-full max-h-[80vh] object-contain rounded-lg"
            />
            <button
              onClick={closeModal}
              className="absolute top-4 right-4 bg-white text-black rounded-full p-2 text-lg font-bold shadow-md hover:shadow-lg"
            >
              ✕
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Rescue;
